import moment from 'moment'

export function formatDateTime(date: string) {
	if (!date || date === '') {
		return ''
	}

	const localDate = new Date(moment(date).local().valueOf())

	let localDateString = localDate.toString()
	if (localDateString.indexOf('GMT') > 0) {
		localDateString = localDateString.substring(0, localDateString.indexOf('GMT'))
	}

	return localDateString
}
export function getDefaultDateTime() {
	let currentDate = new Date()
	let year = currentDate.getFullYear()
	let month = currentDate.getMonth() + 1

	if (month < 7) {
		year = year - 1
	}

	return `${year}-07-01T14:55:33+00:00`
}
