import { AuthenticatedTemplate, useMsalAuthentication, UnauthenticatedTemplate } from '@azure/msal-react'

import { Outlet } from 'react-router-dom'
import { MenuBar } from './MenuBar'
import { SideNav } from './SideNav'
import { InteractionType } from '@azure/msal-browser'
import './index.scss'
import { Env } from '@env'
import { msalInstance } from '..'
import { GlobalToaster } from '@components/GlobalToaster'
export const Layout = () => {
	const x = useMsalAuthentication(InteractionType.Redirect)
	const role = (msalInstance.getActiveAccount()?.idTokenClaims?.roles || []) as string[]
	const hasRole = role.includes(Env.Role.All)
	return (
		<>
			<AuthenticatedTemplate>
				<MenuBar />
				<section className="layout">
					<section className="sidenav">
						<SideNav />
					</section>
					<section className="outlet">
						{hasRole ? (
							<Outlet />
						) : (
							'You are not authorized to access the system. Check the contact in Help to request the necessary access.'
						)}
					</section>
				</section>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>Logging in.</UnauthenticatedTemplate>
			<GlobalToaster />
		</>
	)
}
