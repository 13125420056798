import { EMigrationStep, MigrationStep } from '@/Api/Types'
import { makeAutoObservable } from 'mobx'
import { NewMigrationContext } from '.'
import { Status, StatusAndDates } from './Types'
export const FilterOptions = ['Not retrieved', 'Retrieved failed', 'Retrieved but checked failed', 'Retrieved and checked passed'] as const

export class Step5Context {
	public constructor(root: NewMigrationContext) {
		this.root = root
		this.statusMap = new Map()
		makeAutoObservable(this)
	}
	get CanNext(): boolean {
		return false
	}
	public get Status() {
		const map = new Map<number, Status<['Convert', 'Check']>>()
		this.StatusMap.forEach((v, k) => {
			map.set(k, {
				ConvertStatus: v[MigrationStep.FormatConvert]?.status,
				ConvertEndTime: v[MigrationStep.FormatConvert]?.finishTime,
				ConvertError: v[MigrationStep.FormatConvert]?.errorDetail,
				CheckStatus: v[MigrationStep.CheckAfterConvert]?.status,
				CheckEndTime: v[MigrationStep.CheckAfterConvert]?.finishTime,
				CheckError: v[MigrationStep.CheckAfterConvert]?.errorDetail,
			})
		})
		return map
	}
	private statusMap: Map<number, { [key in EMigrationStep]: StatusAndDates }>
	public get StatusMap() {
		return this.statusMap
	}
	public set StatusMap(v: Map<number, { [key in EMigrationStep]: StatusAndDates }>) {
		this.statusMap = v
	}
	private root: NewMigrationContext
}
