import { getAppInsights } from '@components/DataQuery/Utilities/TelemetryService'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { PaginationResponse } from './Types'

export type OperationResult<T> = {
	success: boolean
	errorCode: number | null
	errorMessage: string | null
	result: T | null | undefined
}
let appInsight = getAppInsights()
export const WrapperPagination = <T>(res: PaginationResponse<T>) => {
	return { total: res.pagination.total, list: res.searchResult }
}
export const WrapperFetch = <T>() => {
	return (response: Response) => {
		if (!response.ok) {
			appInsight.trackException({
				error: new Error(`${response.url} ${response.status}`),
				severityLevel: SeverityLevel.Error,
			})
			Promise.reject(response)
		}
		return new Promise<T>((resolve, reject) => {
			response
				.json()
				.then((r) => r as OperationResult<T>)
				.then((result) => {
					if (result.success) resolve(result.result!)
					else {
						appInsight.trackException({
							error: new Error(`${response.url} ${JSON.stringify(result)}`),
							severityLevel: SeverityLevel.Error,
						})
						reject(result)
					}
				})
		})
	}
}
