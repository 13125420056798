import { useCallback, useState } from 'react'

export const useToggle = (defaultValue: boolean = false) => {
	const [value, setValue] = useState(!!defaultValue)

	const toggle = useCallback(() => {
		setValue((x) => !x)
	}, [])

	return [value, toggle, setValue] as const
}
