import { MsalProvider } from '@azure/msal-react'
import { FluentProvider, Toaster, webDarkTheme, webLightTheme } from '@fluentui/react-components'
import React, { useEffect, useState } from 'react'
import { msalInstance } from '.'
import { AppRouter } from './Route'
const query = '(prefers-color-scheme: dark)'
export const App = () => {
	const [match, setMatch] = useState(() => {
		return matchMedia(query).matches
	})
	useEffect(() => {
		const queryList = matchMedia(query)
		const updateMatch = () => {
			setMatch(queryList.matches)
		}

		updateMatch()
		queryList.addEventListener('change', updateMatch)

		return () => {
			queryList.removeEventListener('change', updateMatch)
		}
	}, [])
	return (
		<MsalProvider instance={msalInstance}>
			<FluentProvider theme={match ? webDarkTheme : webLightTheme}>
				<Toaster position="top-end" />
				<AppRouter />
			</FluentProvider>
		</MsalProvider>
	)
}
