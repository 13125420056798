import { Toast, ToastBody, ToastIntent, ToastTitle, useToastController } from '@fluentui/react-components'
import { Debug } from '@/Utilities/Debug'
type Listener = (content: string, intent: ToastIntent) => void
class Context {
	public constructor() {
		this.listener = () => {}
	}
	public Emit(content: string, intent: ToastIntent) {
		this.listener(content, intent)
	}
	private listener: Listener
	public get Listener() {
		return this.listener
	}
	public set Listener(v: Listener) {
		this.listener = v
	}
}
const c = new Context()
export const GlobalToaster = () => {
	const { dispatchToast } = useToastController()
	const listener: Listener = (e, i) => {
		dispatchToast(
			<Toast>
				<ToastTitle>Error</ToastTitle>
				<ToastBody>{e}</ToastBody>
			</Toast>,
			{
				intent: i,
			}
		)
	}
	c.Listener = listener
	return <></>
}
Debug.StorgeGlobal('Context', c)
export const EmitToast = (content: string, intent: ToastIntent) => {
	c.Emit(content, intent)
}
