import { Button, Spinner } from '@fluentui/react-components'
import React, { useState } from 'react'
import './ActionButton.scss'
type ButtonProps = Parameters<typeof Button>[0]
type Props = {
	onClick: () => Promise<unknown> | unknown
	children: React.ReactNode
	disabled?: ButtonProps['disabled']
	appearance?: ButtonProps['appearance']
	className?: ButtonProps['className']
}

export const ActionButton = ({ onClick, children, disabled, className, ...args }: Props) => {
	const [isLoading, setLoading] = useState(false)
	const click = () => {
		setLoading(true)
		const result = onClick()
		if (result instanceof Promise) {
			result.finally(() => {
				setLoading(false)
			})
		} else {
			setLoading(false)
		}
	}
	return (
		<Button
			disabled={isLoading || disabled}
			className={'action-button ' + className}
			icon={isLoading ? <Spinner /> : undefined}
			onClick={click}
			{...args}
		>
			{children}
		</Button>
	)
}
