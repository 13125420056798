import {
	QueryExpression,
	getExpression,
	getSelectedExpressions,
	isGroup,
	getPositionWithinParent,
	findExpression,
	getClosestCommonAncestor,
	exprEquals,
	subTreeSelected,
} from './QueryBuilderUtils'

import { groupHoverText_notEnough, groupHoverText_noIntersection, groupHoverText_alreadyExists, groupHoverText_okay } from './QueryBuilderConstants'

/**
 * @description Initializes query expression tree
 * @returns Expression tree root node with a single empty child
 */
export const initExprTree = (): QueryExpression => {
	const root = new QueryExpression()
	root.children = []

	const emptyExpr = new QueryExpression()
	emptyExpr.parent = root
	root.children.push(emptyExpr)

	return root
}

/**
 * @description Overwrites the expression at target rowIndex
 * @param root top-level parent node of expression tree
 * @param rowIndex Position in QueryRows of target expression
 * @param newExpression New values for target expression to use
 */
export const setExpression = (root: QueryExpression, rowIndex: number, newExpression: QueryExpression) => {
	let targetExpr = getExpression(root, rowIndex)
	if (targetExpr !== null) {
		targetExpr = {
			...targetExpr,
			group: newExpression.group,
			andOr: newExpression.andOr,
			field: newExpression.field,
			fieldType: newExpression.fieldType,
			operator: newExpression.operator,
			value: newExpression.value,
		}
	}
}

/**
 * @description Appends a new expression to the root's children array
 * @param root top-level parent node of expression tree
 */
export const appendNewExpression = (root: QueryExpression) => {
	const newExpr = new QueryExpression()
	newExpr.parent = root
	newExpr.andOr = 'And'
	root.children.push(newExpr)
}

/**
 * @description Adds a new empty expression before the specified index
 * @param root top-level parent node of expression tree
 * @param rowIndex Position in array to add new expression
 */
export const insertNewExpression = (root: QueryExpression, rowIndex: number) => {
	const newExpr = new QueryExpression()
	if (rowIndex === 0) {
		const prevFirstExpr = getExpression(root, 0)
		prevFirstExpr.andOr = 'And'
		// add newExpr as the first child of our root node
		newExpr.parent = root
		root.children.splice(0, 0, newExpr)
	} else {
		newExpr.andOr = 'And'
		const targetExpr = getExpression(root, rowIndex)
		let sibIndex = getPositionWithinParent(targetExpr)
		if (sibIndex === 0) {
			// add newExpr to the end of parent group
			let targetGroup = targetExpr.parent
			sibIndex = getPositionWithinParent(targetGroup)
			while (sibIndex === 0) {
				targetGroup = targetGroup.parent
				sibIndex = getPositionWithinParent(targetGroup)
			}
			newExpr.parent = targetGroup.parent
			targetGroup.parent.children.splice(sibIndex, 0, newExpr)
		} else {
			// add newExpr before targetExpr within current group
			newExpr.parent = targetExpr.parent
			targetExpr.parent.children.splice(sibIndex, 0, newExpr)
		}
	}
}

/**
 * @description Ungroups target expresstion group
 * @param groupID ID of target expression group
 */
export const ungroupExpressions = (root: QueryExpression, groupID: number) => {
	const group = findExpression(root, groupID)
	const index = getPositionWithinParent(group)

	for (const child of group.children) {
		child.parent = group.parent
	}

	group.parent.children.splice(index, 1, ...group.children)
	group.parent = null!
}

/**
 * @description Creates a new expression group consisting of selected rows, and inserts group into tree at position of closest common ancestor
 * @param root top-level parent node of expression tree
 */
export const groupSelectedExpressions = (root: QueryExpression) => {
	const selectedRows = getSelectedExpressions(root)

	// determine closestCommonAncestor of all selected expressions
	let closestCommonAncestor = getClosestCommonAncestor(selectedRows[0].expr, selectedRows[1].expr)
	for (let j = 2; j < selectedRows.length; j += 1) {
		closestCommonAncestor = getClosestCommonAncestor(closestCommonAncestor, selectedRows[j].expr)
	}

	// determine where new group belongs under closestCommonAncestor
	let curr = selectedRows[0].expr
	while (!exprEquals(curr.parent, closestCommonAncestor)) {
		curr = curr.parent
	}
	const firstSelectedChildIndex = getPositionWithinParent(curr)

	// create newGroup to insert into tree, and add selected expression as children
	const newGroup = new QueryExpression()
	newGroup.children = []
	for (const row of selectedRows) {
		curr = row.expr
		curr.group = false
		while (!exprEquals(curr.parent, closestCommonAncestor)) {
			curr = curr.parent
		}
		if (!newGroup.children.includes(curr)) {
			newGroup.children.push(curr)
		}
	}

	// removed selected subtrees from closestCommonAncestor
	for (const child of newGroup.children) {
		const index = closestCommonAncestor.children.indexOf(child)
		child.parent = newGroup
		closestCommonAncestor.children.splice(index, 1)
	}

	// insert new group into children of closestCommonAncestor
	newGroup.parent = closestCommonAncestor
	closestCommonAncestor.children.splice(firstSelectedChildIndex, 0, newGroup)
}

/**
 * @descriptions Determines the hover text for the expression grouping button
 * @param root top-level parent node of expression tree
 * @returns String indicating why selected expression cannot be grouped, or simply "Group" if they can
 */
export const getGroupingHoverText = (root: QueryExpression) => {
	const selectedRows = getSelectedExpressions(root)
	// cannot group less than 2 rows
	if (selectedRows.length < 2) return groupHoverText_notEnough
	// cannot group rows that are not adjacent
	if (!rowsAdjacent(selectedRows)) return groupHoverText_noIntersection

	// cannot create a group that already exists
	const expressions = selectedRows.map((row) => row.expr)
	if (isGroup(expressions)) return groupHoverText_alreadyExists

	// ensure all subgroups of the selected expressions' closestCommonAncestor are selected
	let closestCommonAncestor = expressions[0]
	for (let i = 1; i < expressions.length; i += 1) {
		closestCommonAncestor = getClosestCommonAncestor(closestCommonAncestor, expressions[i])
	}
	const subGroups = getSubGroups(closestCommonAncestor)
	for (const subGroup of subGroups) {
		if (!subTreeSelected(subGroup)) return groupHoverText_noIntersection
	}

	return groupHoverText_okay
}

/**
 * @description Determines if array of selected rows are all adjacent (sequential with no gaps).
 * @param selectedRows array of selected expressions and their respective rowIndexes
 * @returns true if all selectedRows are adjacent, and false otherwise
 */
export const rowsAdjacent = (
	selectedRows: {
		expr: QueryExpression
		rowIndex: number
	}[]
): boolean => {
	let prevRowIndex = null
	for (const row of selectedRows) {
		const rowIndex = row.rowIndex
		if (prevRowIndex) {
			if (prevRowIndex !== rowIndex - 1) return false
		}
		prevRowIndex = rowIndex
	}

	return true
}

/**
 * @description returns any expression groups found under the given expression tree
 * @param root expression tree
 * @returns list of subgroups found
 */
export const getSubGroups = (root: QueryExpression): QueryExpression[] => {
	const subGroups = []
	const nodesToProcess = []
	nodesToProcess.push(root)
	while (nodesToProcess.length > 0) {
		const curr = nodesToProcess.pop() as QueryExpression
		if (curr.children) {
			if (curr !== root) subGroups.push(curr)
			for (let childIndex = curr.children.length - 1; childIndex >= 0; childIndex -= 1) {
				nodesToProcess.push(curr.children[childIndex])
			}
		}
	}

	return subGroups
}
