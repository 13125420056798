import {
	Body1,
	Breadcrumb,
	BreadcrumbButton,
	BreadcrumbDivider,
	BreadcrumbItem,
	Button,
	Link,
	SkeletonItem,
	Spinner,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from '@fluentui/react-components'
import { AddSquareRegular, ChevronDown24Filled, ChevronRight24Filled, EditRegular } from '@fluentui/react-icons'
import './index.scss'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Api } from '@/Api'
import { useNavigate } from 'react-router-dom'
import { FormatDate } from '@/Utilities'
import { useRequest } from 'ahooks'
import { MigrationTask } from '@/Api/Types'
const selectIcon = (expanded: boolean) => (expanded ? ChevronDown24Filled : ChevronRight24Filled)

export const AllMigrations = observer(() => {
	const navigate = useNavigate()
	const state = useLocalObservable(() => ({
		isMyMigrationsExpanded: false,
		isShareMigrationsExpanded: false,
		toggleMyMigrationsExpanded() {
			this.isMyMigrationsExpanded = !this.isMyMigrationsExpanded
		},
		toggleShareMigrationsExpanded() {
			this.isShareMigrationsExpanded = !this.isShareMigrationsExpanded
		},
		get MyMigrationsIcon() {
			return selectIcon(this.isMyMigrationsExpanded)
		},
		get ShareMigrationsIcon() {
			return selectIcon(this.isShareMigrationsExpanded)
		},
	}))
	const { data, loading } = useRequest(Api.MigrationTask.GetTasks)
	const { data: status, loading: statusLoading } = useRequest(
		() => {
			if (data) return Api.MigrationTask.GetTaskStatus([...data.mine.map((t) => t.id), ...data.shared.map((t) => t.id)])
			else return Promise.resolve(undefined)
		},
		{
			refreshDeps: [data],
		}
	)
	const onClick = (e: React.MouseEvent, id: number | string) => {
		navigate(`/migration/${id}`)
		e.preventDefault()
	}
	const renderStatus = (id: number) => {
		if (statusLoading) return <SkeletonItem size={16} />
		const s = status?.[id] ?? { included: 0, retrieved: 0, configured: 0, converted: 0 }
		if (s.included === 0) return <Body1>Created</Body1>
		return (
			<Body1>
				Processing included: {s.included}, retrieved: {s.retrieved}, configured: {s.configured}, converted: {s.converted}, published: 0
			</Body1>
		)
	}
	const renderItems = (tasks?: MigrationTask[]) => {
		if (!tasks) return <></>
		return tasks.map((item) => (
			<TableRow key={item.id}>
				<TableCell>{item.migrationName}</TableCell>
				<TableCell>{item.targetDocSetName}</TableCell>
				<TableCell>{renderStatus(item.id)}</TableCell>
				<TableCell>{FormatDate(item.createdTime)}</TableCell>
				<TableCell>{item.createdBy}</TableCell>
				<TableCell>{FormatDate(item.lastModifiedTime)}</TableCell>
				<TableCell>{item.lastModifiedBy ?? item.createdBy}</TableCell>

				<TableCell className="operate">
					<Link href={`/migration/${item.id}`} onClick={(e) => onClick(e, item.id)}>
						<EditRegular />
					</Link>
				</TableCell>
			</TableRow>
		))
	}
	return (
		<section className="all-migrations">
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbButton href="/">Home</BreadcrumbButton>
				</BreadcrumbItem>
				<BreadcrumbDivider />
				<BreadcrumbItem>
					<BreadcrumbButton href="/migration" current>
						All Migrations
					</BreadcrumbButton>
				</BreadcrumbItem>
			</Breadcrumb>
			<section className="toolbar">
				<Button icon={<AddSquareRegular />} iconPosition="before" appearance="primary" onClick={(e) => onClick(e, 'new')}>
					New Migration
				</Button>
			</section>
			<Table noNativeElements>
				<TableHeader>
					<TableRow>
						<TableHeaderCell>Migration Name</TableHeaderCell>
						<TableHeaderCell>Target Docset</TableHeaderCell>
						<TableHeaderCell>Status</TableHeaderCell>
						<TableHeaderCell>Created date</TableHeaderCell>
						<TableHeaderCell>Created by</TableHeaderCell>
						<TableHeaderCell>Last modified date</TableHeaderCell>
						<TableHeaderCell>Last modified by</TableHeaderCell>
						<TableHeaderCell className="operate" />
					</TableRow>
				</TableHeader>
				<TableBody>
					<TableRow className="group-row" onClick={state.toggleMyMigrationsExpanded}>
						<TableCell>
							<state.MyMigrationsIcon />
							My Migrations
						</TableCell>
					</TableRow>
					{state.isMyMigrationsExpanded && (loading ? <Spinner /> : renderItems(data?.mine))}
					<TableRow className="group-row" onClick={state.toggleShareMigrationsExpanded}>
						<TableCell>
							<state.ShareMigrationsIcon />
							Shared Migrations
						</TableCell>
					</TableRow>
					{state.isShareMigrationsExpanded && (loading ? <Spinner /> : renderItems(data?.shared))}
				</TableBody>
			</Table>
		</section>
	)
})
