import { EMigrationStep, MigrationStep } from '@/Api/Types'
import { makeAutoObservable } from 'mobx'
import { Status, StatusAndDates } from './Types'
import { NewMigrationContext } from '.'
export class Step3Context {
	public constructor(private root: NewMigrationContext) {
		this.statusMap = new Map()

		makeAutoObservable(this)
	}
	public get Status() {
		const map = new Map<number, Status<['Retrieve', 'Check']>>()
		this.StatusMap.forEach((v, k) => {
			map.set(k, {
				RetrieveStatus: v[MigrationStep.ArticleSync]?.status,
				RetrieveEndTime: v[MigrationStep.ArticleSync]?.finishTime,
				RetrieveError: v[MigrationStep.ArticleSync]?.errorDetail,
				CheckStatus: v[MigrationStep.ContentCheck]?.status,
				CheckEndTime: v[MigrationStep.ContentCheck]?.finishTime,
				CheckError: v[MigrationStep.ContentCheck]?.errorDetail,
			})
		})
		return map
	}
	private statusMap: Map<number, { [key in EMigrationStep]: StatusAndDates }>
	public get StatusMap() {
		return this.statusMap
	}
	public set StatusMap(v: Map<number, { [key in EMigrationStep]: StatusAndDates }>) {
		this.statusMap = v
	}
}
