import { Env } from '@env'
import { Fetch } from './Fetch'
import {
	EMigrationStep,
	EStep5Status,
	MigrationItemStatusRespond,
	Pagination,
	PaginationResponse,
	MigrationArticle as TMigrationArticle,
} from './Types'
import { WrapperPagination } from './OperationResult'
const Root = `${Env.BackendApiUrl}api/migration/MigrationArticle`
const GetArticles = (
	migrationTaskId: number,
	query: { articleId?: string; title?: string; status?: EStep5Status[]; isConverted?: boolean },
	page?: Pagination
) => {
	return Fetch.Get<PaginationResponse<TMigrationArticle>>({
		url: `${Root}/GetMigrationArticles`,
		query: {
			taskId: migrationTaskId,
			...query,
			...page,
		},
	}).then(WrapperPagination)
}
const ConfigArticle = (
	models: Required<Pick<TMigrationArticle, 'id' | 'msService' | 'msTopic' | 'permissionType' | 'targetRepoFileName' | 'targetRepoFolder'>>[]
) => {
	return Fetch.Post<string>(`${Root}/ConfigMigrationArticles`, models)
}
const ConvertCheck = (ids: number[]) => {
	return Fetch.Post<string>(`${Root}/BeginConvertCheck`, ids)
}
const GetArticleStatusAndDates = (ids: number[], steps?: EMigrationStep[]) => {
	return Fetch.Post<MigrationItemStatusRespond[]>(`${Root}/GetArticleStatusAndDates`, { items: ids, steps })
}
export const MigrationArticle = {
	GetArticles,
	ConfigArticle,
	ConvertCheck,
	GetArticleStatusAndDates,
}
