import { makeAutoObservable, observable } from 'mobx'
import { DefaultColums } from '@/Special/DefaultColums'
import { ArticleData } from './ArticleData'
import { RootConfig } from '@/Config/Const'
export type DynamicColumn = {
	Props: string
	Text: string
	Width: number
	DisplayName: string
}
export class DataQueryResult {
	public constructor() {
		this.pageIndex = 0
		this.pageSize = RootConfig.SonicMaxQueryItem
		this.total = 0
		this.articles = []
		this.hasMore = false
		this.loading = false
		this.columns = [...DefaultColums]
		makeAutoObservable(this, {
			articles: observable.ref,
			columns: observable.ref,
		})
	}
	public Clear() {
		this.pageIndex = 0
		this.pageSize = RootConfig.SonicMaxQueryItem
		this.total = 0
		this.articles = []
		this.hasMore = false
		this.loading = false
	}
	public get Columns() {
		return this.columns
	}
	public set Columns(val: DynamicColumn[]) {
		this.columns = val
	}
	public columns: DynamicColumn[]
	private loading: boolean
	public get Loading(): boolean {
		return this.loading
	}
	public set Loading(v: boolean) {
		this.loading = v
	}
	private hasMore: boolean
	public get HasMore(): boolean {
		return this.hasMore
	}
	public set HasMore(v: boolean) {
		this.hasMore = v
	}

	public pageIndex: number
	public get PageIndex(): number {
		return this.pageIndex
	}
	public set PageIndex(v: number) {
		this.pageIndex = v
	}

	public pageSize: number
	public get PageSize(): number {
		return this.pageSize
	}
	public set PageSize(v: number) {
		this.pageSize = v
	}

	public total: number
	public get Total(): number {
		return this.total
	}
	public set Total(v: number) {
		this.total = v
	}

	public articles: ArticleData[]
	public get Articles() {
		return this.articles
	}
	public set Articles(v: ArticleData[]) {
		this.articles = v
	}
}
