import { ColumnSorting } from '@components/DataQuery/DataQueryContext'
import { DynamicColumn } from '@components/DataQuery/DataQueryContext/DataQueryResult'

export const DefaultColums: DynamicColumn[] = [
	{
		Text: 'Metadata > ContentID',
		Props: 'ContentID',
		Width: 200,
		DisplayName: 'Metadata > ContentID',
	},
	{
		Text: 'Article > Locale',
		Props: 'Locale',
		Width: 200,
		DisplayName: 'Article > Locale',
	},
	{
		Text: 'Metadata > RepositoryName',
		Props: 'RepositoryName',
		Width: 200,
		DisplayName: 'Metadata > RepositoryName',
	},
	{
		Text: 'Properties > LastPublishDate',
		Props: 'LastPublishDate',
		Width: 200,
		DisplayName: 'Properties > LastPublishDate',
	},
	{
		Text: 'Properties > ArticleCreatedDate',
		Props: 'ArticleCreatedDate',
		Width: 200,
		DisplayName: 'Properties > ArticleCreatedDate',
	},
	{
		Text: 'Metadata > SapName',
		Props: 'SapName',
		Width: 200,
		DisplayName: 'Metadata > SapName',
	},
	{
		Text: 'Metadata > SapStatus',
		Props: 'SapStatus',
		Width: 200,
		DisplayName: 'Metadata > SapStatus',
	},
	{
		Text: 'Properties > Contacts > ContentOwner',
		Props: 'ContentOwner',
		Width: 200,
		DisplayName: 'Properties > Contacts > ContentOwner',
	},
	{
		Text: 'Metadata > Keyword',
		Props: 'Keyword',
		Width: 200,
		DisplayName: 'Metadata > Keyword',
	},
	{
		Text: 'Article > Title',
		Props: 'Title',
		Width: 200,
		DisplayName: 'Article > Title',
	},
	{
		Text: 'Metrics > NoOfPageViews',
		Props: 'NoOfPageViews',
		Width: 200,
		DisplayName: 'Metrics > NoOfPageViews',
	},
	{
		Text: 'Metrics > NoOfHelpfulVotes',
		Props: 'NoOfHelpfulVotes',
		Width: 200,
		DisplayName: 'Metrics > NoOfHelpfulVotes',
	},
	{
		Text: 'Metadata > RevisionNumber',
		Props: 'RevisionNumber',
		Width: 200,
		DisplayName: 'Metadata > RevisionNumber',
	},
	{
		Text: 'Properties > Contacts > WriterName',
		Props: 'WriterName',
		Width: 200,
		DisplayName: 'Properties > Contacts > WriterName',
	},
]
export const DefaultColumnSorting: ColumnSorting[] = [
	{
		Props: 'ArticleArchivingRemainingDays',
		Sorting: 'desc',
	},
	{
		Props: 'SapStatus',
		Sorting: 'desc',
	},
]
