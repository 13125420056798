import { QueryExpression } from '../Components/DataQueryBuilder/QueryBuilderUtils'
import { IQueryExpressions } from '../Interface/IQueryExpressions'
export function getOderByFields(metadataSchema: string) {
	let orderBy = 'ArticleArchivingRemainingDays desc, SapStatus desc'
	if (metadataSchema?.startsWith('gitpub-metadata-schema-others-')) {
		orderBy = 'NoOfBrokenLinks desc'
	} else if (metadataSchema?.startsWith('gitpub-metadata-schema-404links-')) {
		orderBy = 'NoOfArticles desc'
	} else if (metadataSchema?.startsWith('gitpub-metadata-schema-policheck-') || metadataSchema?.startsWith('AzureKBAPI')) {
		orderBy = 'Domain'
	} else if (metadataSchema?.startsWith('gitpub-metadata-schema-customizedarticles-')) {
		orderBy = 'NoOfBrokenLinks desc'
	} else if (metadataSchema?.startsWith('gitpub-metadata-schema-policheckaggregateddata-')) {
		orderBy = 'Domain'
	} else if (metadataSchema?.startsWith('gitpub-metadata-schema-lmc-')) {
		orderBy = 'RepoName'
	} else if (metadataSchema?.startsWith('gitpub-metadata-schema-smc-') || metadataSchema?.startsWith('gitpub-metadata-schema-sxc-')) {
		orderBy = 'Locale'
	} else if (metadataSchema?.startsWith('gitpub-metadata-schema-policheckaggregateddata-')) {
		orderBy = 'Domain'
	} else if (metadataSchema?.startsWith('csswikicontent-')) {
		orderBy = 'OrganizationName'
	} else if (metadataSchema?.startsWith('gitpub-metadata-schema-media-')) {
		orderBy = 'Domain'
	} else if (metadataSchema?.startsWith('issue-center-')) {
		orderBy = 'RiskArea'
	} else if (
		metadataSchema?.startsWith('ccisevergreen-') ||
		metadataSchema?.startsWith('ccis-csswiki-') ||
		metadataSchema?.startsWith('ccis-smc-') ||
		metadataSchema?.startsWith('ccis-sxc-') ||
		metadataSchema?.startsWith('ccis-lmc-') ||
		metadataSchema?.startsWith('ccisppe-evergreen-') ||
		metadataSchema?.startsWith('ccisppe-csswiki-') ||
		metadataSchema?.startsWith('ccisppe-smc-') ||
		metadataSchema?.startsWith('ccisppe-sxc-') ||
		metadataSchema?.startsWith('ccisppe-lmc-') ||
		metadataSchema?.startsWith('ccisprod-evergreen-') ||
		metadataSchema?.startsWith('ccisprod-csswiki-') ||
		metadataSchema?.startsWith('ccisprod-smc-') ||
		metadataSchema?.startsWith('ccisprod-lmc-') ||
		metadataSchema?.startsWith('ccisprod-sxc-')
	) {
		orderBy = 'CopilotDataUsage/CSSLastUpdatedDateTime desc'
	}
	return orderBy
}
/**
 * build query expression condition
 */
export function buildConditionJson(children: QueryExpression[]) {
	let expList: IQueryExpressions[] = []
	for (var i = 0; i < children.length; i++) {
		const node = children[i]
		if (node.children) {
			expList.push({
				id: node.id,
				group: false,
				andOr: null!,
				field: null!,
				fieldType: null!,
				operator: null!,
				value: null!,
				children: buildConditionChildrenJson(node.children),
			})
		} else {
			expList.push({
				id: node.id,
				group: node.group,
				andOr: node.andOr,
				field: node.field,
				fieldType: node.fieldType,
				operator: node.operator,
				value: node.value,
			})
		}
	}

	return expList
}
/**
 * build children expression condition
 * @param childNode
 * @returns
 */
function buildConditionChildrenJson(childNode: IQueryExpressions[]) {
	let currentChildren: IQueryExpressions[] = []
	for (let i = 0; i < childNode.length; i++) {
		let node = childNode[i]
		if (node.children) {
			currentChildren.push({
				id: node.id,
				group: false,
				andOr: null!,
				field: null!,
				fieldType: null!,
				operator: null!,
				value: null!,
				children: buildConditionChildrenJson(node.children),
			})
		} else {
			currentChildren.push({
				id: node.id,
				group: node.group,
				andOr: node.andOr,
				field: node.field,
				fieldType: node.fieldType,
				operator: node.operator,
				value: node.value,
			})
		}
	}

	return currentChildren
}
