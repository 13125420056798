import { ColumnOptionsDialog } from './ColumnOptionsDialog'
import { ConfigurationSettingsDialog } from './ConfigurationSettingsDialog'

export const DialogProvider = () => {
	return (
		<>
			<ColumnOptionsDialog />
			<ConfigurationSettingsDialog />
		</>
	)
}
