import { TableCellLayout, Input, Popover, PopoverSurface, PositioningImperativeRef } from '@fluentui/react-components'
import { useEffect, useRef } from 'react'

type Props = { name: string; showPopover: boolean; setName: (val: string) => void; disabled: boolean }
export const Step4NameInput = (props: Props) => {
	const ref = useRef<HTMLInputElement>(null)
	const positioningRef = useRef<PositioningImperativeRef>(null)
	useEffect(() => {
		if (ref.current && positioningRef.current) {
			positioningRef.current.setTarget(ref.current)
		}
	}, [ref, positioningRef])
	return (
		<TableCellLayout truncate>
			<Input
				value={props.name}
				onChange={(e, o) => {
					props.setName(o.value)
				}}
				disabled={props.disabled}
				className="truncated"
				style={{ width: '100%' }}
				ref={ref}
			/>
			<Popover withArrow trapFocus open={props.showPopover} positioning={{ positioningRef }}>
				<PopoverSurface>
					<section>
						<section>Use only lowercase letters, numbers, and hyphens.</section>
					</section>
				</PopoverSurface>
			</Popover>
		</TableCellLayout>
	)
}
