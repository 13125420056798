import { Api } from '@/Api'
import { MigrationArticle } from '@/Api/Types'
import { ArticleData } from '@components/DataQuery/DataQueryContext/ArticleData'
import {
	Dialog,
	Button,
	DialogActions,
	DialogBody,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	DialogContent,
	Link,
	Spinner,
} from '@fluentui/react-components'
import { LinkRegular } from '@fluentui/react-icons'
import { useToggle } from 'ahooks'
import { useEffect, useRef, useState } from 'react'
type Props = {
	open: boolean
	onClose: () => void
	row?: MigrationArticle | null
}
export const Step4PreviewAction = (props: Props) => {
	const ref = useRef<HTMLIFrameElement>(null)
	const url = `https://internal.evergreen.microsoft.com/en-us/topic/${props.row?.articleSourceId ?? ''}`
	const [loading, { set }] = useToggle()
	const [doc, setDoc] = useState<Document | null>(null)
	useEffect(() => {
		if (props.open && url) {
			set(true)
			Api.Proxy.GetContent(url).then((res) => {
				const parser = new DOMParser()
				const parsedDocument = parser.parseFromString(res, 'text/html')
				setDoc(parsedDocument)

				set(false)
			})
		}
	}, [props.open, url])
	useEffect(() => {
		if (doc && ref.current) {
			const r = ref.current
			r.contentDocument!.head.innerHTML = `<base href="https://internal.evergreen.microsoft.com/" target="_blank"/>${doc.head.innerHTML}`
			r.contentDocument!.body.append(doc.body)
		}
	}, [doc, ref])
	return (
		<Dialog open={props.open}>
			<DialogSurface className="preview-dialog ">
				<DialogBody>
					<DialogTitle>
						Preview
						<Link href={url} target="_blank" style={{ verticalAlign: 'middle' }}>
							<LinkRegular />
						</Link>
					</DialogTitle>
					<DialogContent>
						{loading ? <Spinner /> : url && <iframe ref={ref} className="article-content" title="Preview"></iframe>}
					</DialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary" onClick={() => props.onClose()}>
								Close
							</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}
