import React, { useEffect, useMemo, useState } from 'react'
import { CommandBar, ICommandBarItemProps, Text, Callout, Link, Persona, PersonaSize } from '@fluentui/react'
import './MenuBar.css'
import { msalInstance } from '../..'
import './MenuBar.css'
import { Toast, ToastBody, ToastTitle, useToastController } from '@fluentui/react-components'

export const MenuBar = () => {
	const [userInfo, setUserInfo] = useState({ longname: '', shorname: '' })
	const [isCalloutVisible, setCalloutVisible] = useState(false)
	const { dispatchToast } = useToastController()
	useEffect(() => {
		const userAccount = msalInstance.getActiveAccount()!
		const longname = userAccount.username
		setUserInfo({ longname, shorname: userAccount.name ?? '' })
	}, [])
	console.dir(userInfo)

	const showCallOut = useMemo(() => {
		return () => {
			setCalloutVisible((v) => !v)
		}
	}, [])
	const logout = () => {
		msalInstance.logoutPopup()
		dispatchToast(
			<Toast>
				<ToastTitle>Success</ToastTitle>
				<ToastBody>You signed out of your account.</ToastBody>
			</Toast>,
			{ intent: 'success' }
		)
	}
	const { items, farItems } = useMemo(() => {
		const items: ICommandBarItemProps[] = []

		items.push({
			name: 'Micorsoft',
			key: 'Micorsoft',
			className: 'ms-log',
		})

		items.push({
			name: 'Partner Support Content Management',
			key: 'Partner Support Content Management',
		})

		const farItems: ICommandBarItemProps[] = []

		const userMenuItem: ICommandBarItemProps = {
			key: 'User',
			commandBarButtonAs: () => <Persona size={PersonaSize.size32} text={userInfo.shorname} onClick={showCallOut} />,
		}

		farItems.push(userMenuItem)
		return { items, farItems }
	}, [userInfo, showCallOut])

	return (
		<div style={{ height: '46px' }}>
			<CommandBar items={items} farItems={farItems} className="menuBar" />

			{isCalloutVisible ? (
				<Callout
					ariaLabelledBy="callout-label"
					ariaDescribedBy="callout-description"
					className="msacct-signout"
					gapSpace={0}
					isBeakVisible={true}
					beakWidth={10}
					onDismiss={showCallOut}
				>
					<div className="msacct-callout-inner">
						<div className="callout-header">
							<div className="callout-out">
								<div>
									<Text id="callout-label">Microsoft</Text>
								</div>
								<div>
									<Link onClick={logout} target="_blank">
										Sign Out
									</Link>
								</div>
							</div>
							<div style={{ gridColumn: '1 / 3' }}>
								<Persona size={PersonaSize.size32} text={userInfo.shorname} />
							</div>
						</div>
					</div>
				</Callout>
			) : null}
		</div>
	)
}
