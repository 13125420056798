import { observer } from 'mobx-react-lite'
import { StepProps } from '..'
import { FormatDate } from '@/Utilities'
import {
	Input,
	Dropdown,
	Button,
	Table,
	TableHeader,
	TableRow,
	TableSelectionCell,
	TableHeaderCell,
	TableBody,
	TableCell,
	TableCellLayout,
	Link,
} from '@fluentui/react-components'
import { Data } from './Mock'
import { Pagination } from '@components/Pagination'
export const Step6 = observer((props: StepProps) => {
	return (
		<section className="step">
			<section className="step-body">
				<section className="step-filter">
					<Input placeholder="Article Id" />
					<Input placeholder="Title" />
					<Dropdown multiselect placeholder="Status"></Dropdown>
					<Button appearance="primary">Search</Button>
					<Button>Reset</Button>
					<Button appearance="primary" className="main-action">
						Post to Github
					</Button>
					<Button appearance="primary">Publish</Button>
				</section>
				<Table noNativeElements>
					<TableHeader>
						<TableRow>
							<TableSelectionCell checkboxIndicator={{ 'aria-label': 'Select all rows ' }} />
							<TableHeaderCell>Article ID</TableHeaderCell>
							<TableHeaderCell>Article Title</TableHeaderCell>
							<TableHeaderCell>Revision Number</TableHeaderCell>
							<TableHeaderCell>EG.Authors</TableHeaderCell>
							<TableHeaderCell>Last Publish Date</TableHeaderCell>
							<TableHeaderCell>Post Date</TableHeaderCell>
							<TableHeaderCell>Post Status</TableHeaderCell>
							<TableHeaderCell>Review link</TableHeaderCell>
							<TableHeaderCell>Publish Date</TableHeaderCell>
							<TableHeaderCell>Publish Status</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody></TableBody>
				</Table>
				<Pagination
					current={1}
					pageSize={10}
					total={2}
					totalPage={1}
					onChange={() => {}}
					changeCurrent={() => {}}
					changePageSize={() => {}}
				/>
			</section>
			<section className="step-footer">
				<Button onClick={props.OnPrevious}>Previous</Button>
				<Button appearance="primary" onClick={props.OnNext}>
					Next
				</Button>
			</section>
		</section>
	)
})
