import { Docset, EPermissionType, PermissionType } from '@/Api/Types'
import { makeAutoObservable } from 'mobx'
import { StepContext } from './Types'
import { RootConfig } from '@/Config/Const'

export class Step1Context implements StepContext {
	public constructor() {
		this.name = ''
		this.description = ''
		this.disclaimer = RootConfig.DefaultDisclaimer
		this.defaultPermissionType = PermissionType.Private
		this.repoUrl = ''
		this.docset = { folder: '', name: '', permissionedType: '' }
		this.docsetDescription = ''
		this.isDirty = false
		makeAutoObservable(this)
	}

	private isDirty: boolean
	public get IsDirty(): boolean {
		return this.isDirty
	}
	public set IsDirty(v: boolean) {
		this.isDirty = v
	}

	public get CanMoveTo(): boolean {
		return true
	}
	public get CanNext() {
		return !!(this.Name && this.Description && this.DefaultPermissionType && this.RepoUrl && this.Docset.folder)
	}
	private name: string
	public get Name(): string {
		return this.name
	}
	public set Name(v: string) {
		this.name = v
	}

	private description: string
	public get Description(): string {
		return this.description
	}
	public set Description(v: string) {
		this.description = v
	}

	private disclaimer: string
	public get Disclaimer(): string {
		return this.disclaimer
	}
	public set Disclaimer(v: string) {
		this.disclaimer = v
	}

	private defaultPermissionType: EPermissionType
	public get DefaultPermissionType(): EPermissionType {
		return this.defaultPermissionType
	}
	public set DefaultPermissionType(v: EPermissionType) {
		this.defaultPermissionType = v
	}

	private repoUrl: string
	public get RepoUrl(): string {
		return this.repoUrl
	}
	public set RepoUrl(v: string) {
		if (v === this.repoUrl) return
		this.Docset = {
			folder: '',
			name: '',
			permissionedType: '',
		}
		this.repoUrl = v
	}

	private docset: Docset
	public get Docset(): Docset {
		return this.docset
	}
	public set Docset(v: Docset) {
		this.docset = v
	}

	private docsetDescription: string
	public get DocsetDescription(): string {
		return this.docsetDescription
	}
	public set DocsetDescription(v: string) {
		this.docsetDescription = v
	}
}
