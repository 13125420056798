import { Button } from '@fluentui/react-components'
import { Add20Regular, ArrowDownRegular, ArrowUpRegular } from '@fluentui/react-icons'
import { action, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { uniqueId } from 'lodash'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { EnhancedOption, EnhancedSelect } from '@components/EnhancedSelect'
import { SortableColumnDef, SortableColumnsContext } from '.'
import { ArrowSortRegular, DismissRegular } from '@fluentui/react-icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useQueryContext } from '@components/DataQuery/DataQueryContext'
import { useComputed } from '@/Hook/useComputed'
const SortableColumn = observer(({ column, store }: { column: SortableColumnDef; store: SortableColumnsContext }) => {
	const context = useQueryContext()

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: column.id })
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	}
	const usedFields = useComputed(() => {
		return new Set<string>(store.currentColumns.filter((c) => c.id !== column.id).map((c) => c.Props))
	}, [column])
	const availableFields = useComputed<EnhancedOption[]>(() => {
		return context.Fields.map((f) => ({ value: f.FieldName, text: f.DisplayName, disable: usedFields.has(f.FieldName) }))
	}, [usedFields])
	const removeColumn = () => {
		runInAction(() => {
			store.currentColumns.splice(
				store.currentColumns.findIndex((c) => c.id === column.id),
				1
			)
		})
	}
	return (
		<section style={style} className="column-options-row" key={column.id}>
			<section ref={setNodeRef} {...attributes} {...listeners}>
				<ArrowSortRegular className="sort-handler" />
			</section>
			<EnhancedSelect
				value={column.Props}
				options={availableFields}
				onOptionSelect={(v) => {
					runInAction(() => {
						column.Props = v as SortableColumnDef['Props']
					})
				}}
			/>
			{column.Sorting === 'asc' && <ArrowUpRegular className="sorting-handler" />}
			{column.Sorting === 'desc' && <ArrowDownRegular className="sorting-handler" />}
			<DismissRegular className="dismiss" onClick={removeColumn} />
		</section>
	)
})

type Props = {
	store: SortableColumnsContext
}
export const SortableColumns = observer(({ store }: Props) => {
	const addColumn = action(() => {
		store.currentColumns.push({
			Props: undefined as any,
			Sorting: 'desc',
			id: uniqueId(),
		})
	})
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)
	const items = store.currentColumns
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event

		if (active.id !== over!.id) {
			const oldIndex = items.findIndex((i) => i.id === active.id)
			const newIndex = items.findIndex((i) => i.id === over?.id)
			runInAction(() => {
				store.currentColumns = arrayMove(items, oldIndex, newIndex)
			})
		}
	}
	return (
		<section>
			<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
				<SortableContext items={store.currentColumns} strategy={verticalListSortingStrategy}>
					{items.map((item) => (
						<SortableColumn key={item.id} column={item} store={store} />
					))}
				</SortableContext>
			</DndContext>
			<Button appearance="transparent" icon={<Add20Regular />} onClick={addColumn}>
				Add a column
			</Button>
		</section>
	)
})
