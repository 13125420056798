import { Env } from '@env'

export const SonicMsalConfig = {
	auth: {
		clientId: Env.Sonic.ClientID, //"4dc139c0-4bc9-495e-8390-a9e6f690319b", // This is the ONLY mandatory field that you need to supply.
		authority: Env.Sonic.Authority, // Defaults to "https://login.microsoftonline.com/common"
		redirectUri: window.location.origin,
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
}
export const loginRequest = {
	scopes: Env.Sonic.Scopes, //scopes: ["User.Read"]
}
