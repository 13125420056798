/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite'
import { StepProps } from '..'
import { FormatDate } from '@/Utilities'
import {
	Input,
	Dropdown,
	Button,
	Table,
	TableHeader,
	TableRow,
	TableHeaderCell,
	TableBody,
	TableCell,
	TableCellLayout,
	Option,
	useToastController,
	Toast,
	ToastBody,
	ToastTitle,
	Spinner,
	Link,
} from '@fluentui/react-components'
import { EPermissionType, MigrationArticle, PermissionType } from '@/Api/Types'
import { Pagination } from '@components/Pagination'
import { useMigrationContext } from '../Context'
import { useState } from 'react'
import { ActionButton } from '@components/ActionButton'
import { usePagination, useRequest } from 'ahooks'
import { Api } from '@/Api'
import { runInAction } from 'mobx'
import { Step4NameInput } from './Components/Step4NameInput'
import { Step4PreviewAction } from './Components/PreviewArticle'
import { DocumentTextLink20Regular } from '@fluentui/react-icons'
import { EnhancedSelect } from '@components/EnhancedSelect'
const getConfig = () =>
	Api.Config.GetConfig('MS.Service', 'MS.Topic').then((r) => {
		return {
			'MS.Topic': r['MS.Topic'],
			'MS.Service': r['MS.Service'].map((s) => ({
				value: s,
				text: s,
			})),
		}
	})
const getTocs = (repoUrl: string, folder: string) => Api.Github.ListTocs(repoUrl, folder)
export const Step4 = observer((props: StepProps) => {
	const context = useMigrationContext()
	const step4 = context.Step4
	const { data, loading, runAsync, pagination } = usePagination(
		(param) => {
			return Api.MigrationArticle.GetArticles(
				context.MigrationId!,
				{
					articleId,
					title,
				},
				{
					pageNum: param.current,
					pageSize: param.pageSize,
				}
			).then((res) => {
				runInAction(() => {
					res.list.forEach((r) => {
						const e = step4.GetEntity(r)
						e.topic = r.msTopic ?? 'troubleshooting-general'
						e.permission = r.permissionType ?? context.Step1.DefaultPermissionType
						e.name = r.targetRepoFileName ?? ''
						e.folder = r.targetRepoFolder ?? ''
						e.service = r.msService ?? ''
						if (r.targetRepoBranch) {
							e.readonly = true
						}
					})
				})
				return res
			})
		},
		{ defaultPageSize: 10 }
	)
	const { data: configs } = useRequest(getConfig, { cacheKey: 'Step-4-Config' })
	const { data: tocs } = useRequest(() => getTocs(context.Step1.RepoUrl, context.Step1.Docset.folder), {
		refreshDeps: [context.Step1.RepoUrl, context.Step1.Docset.folder],
	})
	const [articleId, setArticleId] = useState('')
	const [title, setTitle] = useState('')
	const { dispatchToast } = useToastController()

	const search = () => {
		runAsync({
			current: 1,
			pageSize: 10,
		})
	}
	const reset = () => {
		setArticleId('')
		setTitle('')
		requestIdleCallback(() => {
			runAsync({
				current: 1,
				pageSize: 10,
			})
		})
	}

	const items = data?.list ?? []
	const config = configs ?? {
		'MS.Service': [],
		'MS.Topic': [],
	}
	const toc = tocs ?? []
	const onNext = async () => {
		await save()
		props.OnNext()
	}
	const save = () => {
		return step4.Save().then(() => {
			dispatchToast(
				<Toast>
					<ToastTitle>Success</ToastTitle>
					<ToastBody>Article saved</ToastBody>
				</Toast>,
				{ intent: 'success' }
			)
		})
	}
	const offset = (pagination.current - 1) * pagination.pageSize
	const [previewRow, setPreviewRow] = useState<MigrationArticle | null>(null)
	const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: MigrationArticle) => {
		e.preventDefault()
		e.stopPropagation()
		setPreviewRow(item)
	}
	return (
		<section className="step">
			<section className="step-body">
				<section className="step-filter">
					<Input placeholder="Article Id" value={articleId} onChange={(e, d) => setArticleId(d.value)} />
					<Input placeholder="Title" value={title} onChange={(e, d) => setTitle(d.value)} />
					<Button appearance="primary" onClick={search}>
						Search
					</Button>
					<Button onClick={reset}>Reset</Button>
					<ActionButton appearance="primary" className="main-action" onClick={save}>
						Save
					</ActionButton>
				</section>
				<Table noNativeElements>
					<TableHeader>
						<TableRow>
							<TableHeaderCell className="line-number" />
							<TableHeaderCell className="link-column"></TableHeaderCell>
							<TableHeaderCell>Article ID</TableHeaderCell>
							<TableHeaderCell>Article Title</TableHeaderCell>
							<TableHeaderCell>Revision Number</TableHeaderCell>
							<TableHeaderCell>EG.Authors</TableHeaderCell>
							<TableHeaderCell>Last Publish Date</TableHeaderCell>
							<TableHeaderCell>ms.topic</TableHeaderCell>
							<TableHeaderCell>ms.service</TableHeaderCell>
							<TableHeaderCell>Permission Type</TableHeaderCell>
							<TableHeaderCell>Folder path</TableHeaderCell>
							<TableHeaderCell>File Name</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{loading ? (
							<Spinner />
						) : (
							items.map((row, i) => {
								const entity = step4.GetEntity(row)
								return (
									<TableRow key={row.id}>
										<TableCell className="line-number">{offset + i + 1}</TableCell>
										<TableCell className="link-column">
											<Link
												href={`https://internal.evergreen.microsoft.com/en-us/topic/${row?.articleSourceId ?? ''}`}
												target="_blank"
												onClick={(e) => onLinkClick(e, row)}
											>
												<DocumentTextLink20Regular />
											</Link>
										</TableCell>
										<TableCell>
											<TableCellLayout title={row.articleSourceId} truncate>
												{row.articleSourceId}
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<TableCellLayout title={row.articleTitle} truncate>
												{row.articleTitle}
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<TableCellLayout title={row.sourceRevisionNumber} truncate>
												{row.sourceRevisionNumber}
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<TableCellLayout title={row.sourceAuthor} truncate>
												{row.sourceAuthor}
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<TableCellLayout title={FormatDate(row.sourceLastPublishDate)} truncate>
												{FormatDate(row.sourceLastPublishDate)}
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<TableCellLayout truncate>
												<Dropdown
													button={
														<span className="truncated" title={entity.topic}>
															{entity.topic}
														</span>
													}
													disabled={entity.readonly}
													onOptionSelect={(e, o) => {
														step4.setTopic(row, o.optionValue!)
													}}
												>
													{config['MS.Topic'].map((o) => (
														<Option key={o} value={o}>
															{o}
														</Option>
													))}
												</Dropdown>
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<TableCellLayout truncate>
												<EnhancedSelect
													value={entity.service}
													onOptionSelect={(o) => step4.setService(row, o)}
													options={config['MS.Service']}
													positioning={{ matchTargetSize: undefined }}
													disabled={entity.readonly}
												/>
												{/* <Dropdown
													button={
														<span className="truncated" title={entity.service}>
															{entity.service}
														</span>
													}
													disabled={entity.readonly}
													inlinePopup={false}
													positioning={{ matchTargetSize: undefined }}
													onOptionSelect={(e, o) => {
														step4.setService(row, o.optionValue!)
													}}
												>
													{config['MS.Service'].map((o) => (
														<Option key={o} value={o}>
															{o}
														</Option>
													))}
												</Dropdown> */}
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<TableCellLayout truncate>
												<Dropdown
													button={
														<span className="truncated" title={entity.permission}>
															{entity.permission}
														</span>
													}
													disabled={entity.readonly}
													onOptionSelect={(e, o) => {
														step4.setPermissionType(row, o.optionValue! as EPermissionType)
													}}
												>
													<Option value={PermissionType.Public}>{PermissionType.Public}</Option>
													<Option value={PermissionType.Private}>{PermissionType.Private}</Option>
													<Option value={PermissionType.Secret}>{PermissionType.Secret}</Option>
												</Dropdown>
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<TableCellLayout truncate>
												<Dropdown
													button={
														<span className="truncated" title={entity.folder}>
															{entity.folder}
														</span>
													}
													disabled={entity.readonly}
													onOptionSelect={(e, o) => {
														step4.setFolder(row, o.optionValue!)
													}}
													positioning={{ matchTargetSize: undefined }}
												>
													{toc.map((o) => (
														<Option key={o} value={o}>
															{o}
														</Option>
													))}
												</Dropdown>
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<Step4NameInput
												name={entity.name}
												showPopover={entity.showPopover}
												setName={(v) => step4.setName(row, v)}
												disabled={entity.readonly}
											/>
										</TableCell>
									</TableRow>
								)
							})
						)}
					</TableBody>
				</Table>
				<Pagination {...pagination} />
				<Step4PreviewAction row={previewRow} open={previewRow !== null} onClose={() => setPreviewRow(null)} />
			</section>
			<section className="step-footer">
				<Button onClick={props.OnPrevious}>Previous</Button>
				<ActionButton appearance="primary" onClick={onNext}>
					Next
				</ActionButton>
			</section>
		</section>
	)
})
