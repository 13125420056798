import { observer } from 'mobx-react-lite'
import { StepProps } from '..'
import { FormatDate, ToMap } from '@/Utilities'
import {
	Input,
	Dropdown,
	Button,
	Table,
	TableHeader,
	TableRow,
	TableSelectionCell,
	TableHeaderCell,
	TableBody,
	TableCell,
	TableCellLayout,
	Option,
	useTableColumnSizing_unstable,
	useTableFeatures,
	createTableColumn,
	Spinner,
	Link,
	SkeletonItem,
	useTableSelection,
	Toast,
	ToastBody,
	ToastTitle,
	useToastController,
} from '@fluentui/react-components'
import { ConvertFailDialog } from './Components/ConvertFailDialog'
import { useMigrationContext } from '../Context'
import { useState, useEffect } from 'react'
import { EStep5Status, MigrationActionStatus, MigrationArticle, MigrationStep, Step5StatsuOptions } from '@/Api/Types'
import { AfterConvertCheckFailDialog } from './Components/AfterConvertCheckFailDialog'
import { Api } from '@/Api'
import { ActionButton } from '@components/ActionButton'
import { usePagination } from 'ahooks'
import { Pagination } from '@components/Pagination'
import { runInAction } from 'mobx'
export const Step5 = observer((props: StepProps) => {
	const context = useMigrationContext()
	const step5 = context.Step5
	const [columnsDef] = useState(
		[
			'EG.Article ID',
			'EG.Article Title',
			'EG.Revision Number',
			'EG.Authors',
			'Retrieve Status',
			'Retrieve Date',
			'Check Status',
			'Check Date',
		].map((i) => createTableColumn<MigrationArticle>({ columnId: i }))
	)
	const [articleId, setArticleId] = useState('')
	const [title, setTitle] = useState('')
	const [filterStatus, setFilterStatus] = useState([] as string[])
	const { data, loading, runAsync, pagination } = usePagination(
		(param) => {
			return Api.MigrationArticle.GetArticles(
				context.MigrationId!,
				{
					articleId,
					title,
					status: filterStatus.map((o) => Number(o) as EStep5Status),
					isConverted: true,
				},
				{
					pageNum: param.current,
					pageSize: param.pageSize,
				}
			)
		},
		{ defaultPageSize: 10, debounceWait: 200 }
	)
	const items = data?.list ?? []

	useEffect(() => {
		if (items.length !== 0)
			Api.MigrationArticle.GetArticleStatusAndDates(items.map((i) => i.id)).then((res) => {
				step5.StatusMap = ToMap(
					res,
					(i) => i.articleId,
					(i) => i.actions
				)
			})
	}, [items])
	const search = () => {
		runAsync({
			current: 1,
			pageSize: 10,
		})
	}
	const reset = () => {
		setArticleId('')
		setTitle('')
		setFilterStatus([])
		requestIdleCallback(() => {
			runAsync({
				current: 1,
				pageSize: 10,
			})
		})
	}
	const {
		getRows,
		tableRef,
		selection: { allRowsSelected, someRowsSelected, toggleAllRows, toggleRow, isRowSelected, selectedRows, deselectRow },
	} = useTableFeatures(
		{
			columns: columnsDef,
			items,
			getRowId(item) {
				return item.id
			},
		},
		[
			useTableColumnSizing_unstable({}),
			useTableSelection({
				selectionMode: 'multiselect',
				defaultSelectedItems: [],
			}),
		]
	)
	const rows = getRows(({ item, rowId }) => {
		const selected = isRowSelected(item.id)
		const status = step5.Status.get(item.id)
		return {
			item: item,
			rowId: rowId,
			...item,
			onClick: () => toggleRow({} as any, item.id),
			selected,
			appearance: selected ? ('brand' as const) : ('none' as const),
			convertStatus: status?.ConvertStatus ?? MigrationActionStatus.Pending,
			convertEndTime: status?.ConvertEndTime ?? '',
			convertError: status?.ConvertError,
			checkStatus: status?.CheckStatus ?? MigrationActionStatus.Pending,
			checkEndTime: status?.CheckEndTime ?? '',
			checkError: status?.CheckError,
			passed: status?.ConvertStatus === 'Success' && status?.CheckStatus === 'Success',
		}
	})
	const GetStatusLoop = (ids: number[]) => {
		Api.MigrationArticle.GetArticleStatusAndDates(ids, [MigrationStep.FormatConvert, MigrationStep.CheckAfterConvert]).then((res) => {
			runInAction(() => {
				res.forEach((val) => {
					step5.StatusMap.set(val.articleId, val.actions)
				})
			})
			const notFinished = new Set(ids)
			res.forEach((item) => {
				if (
					item.actions[MigrationStep.CheckAfterConvert]?.status !== MigrationActionStatus.Success &&
					item.actions[MigrationStep.CheckAfterConvert]?.status !== MigrationActionStatus.Warning
				) {
				} else {
					notFinished.delete(item.articleId)
					deselectRow({} as any, item.articleId)
					runAsync({
						pageSize: pagination.pageSize,
						current: pagination.current,
					})
				}
			})
			if (notFinished.size !== 0) {
				window.setTimeout(() => GetStatusLoop([...notFinished]), 2000)
			}
		})
	}
	const { dispatchToast } = useToastController()
	const convertCheck = () => {
		const ids = [...selectedRows] as number[]
		dispatchToast(
			<Toast>
				<ToastTitle>Info</ToastTitle>
				<ToastBody>In progress.</ToastBody>
			</Toast>,
			{ intent: 'info' }
		)
		return Api.MigrationArticle.ConvertCheck(ids).then(() => {
			window.setTimeout(() => GetStatusLoop(ids), 2000)
		})
	}

	const renderDate = (row: (typeof rows)[number], statusType: 'Convert' | 'Check') => {
		switch (row[statusType === 'Convert' ? 'convertStatus' : 'checkStatus']) {
			case 'Processing':
				return <SkeletonItem />
			case 'Failed':
			case 'Warning':
			case 'Success':
				return FormatDate(row[statusType === 'Convert' ? 'convertEndTime' : 'checkEndTime'])
		}
	}
	const renderStatus = (row: (typeof rows)[number], statusType: 'Convert' | 'Check') => {
		switch (row[statusType === 'Convert' ? 'convertStatus' : 'checkStatus']) {
			case 'Pending':
				return <Button appearance="transparent">Pending</Button>
			case 'Processing':
				return <SkeletonItem />
			case 'Failed':
				return statusType === 'Convert' ? <ConvertFailDialog /> : <AfterConvertCheckFailDialog item={row} />
			case 'Success':
				return <Button appearance="transparent">Success</Button>
			case 'Warning':
				return <AfterConvertCheckFailDialog item={row} />
		}
	}
	const offset = (pagination.current - 1) * pagination.pageSize
	return (
		<section className="step">
			<section className="step-body">
				<section className="step-filter">
					<Input placeholder="Article Id" value={articleId} onChange={(e, d) => setArticleId(d.value)} />
					<Input placeholder="Title" value={title} onChange={(e, d) => setTitle(d.value)} />
					<Dropdown
						selectedOptions={filterStatus}
						multiselect
						placeholder="Status"
						onOptionSelect={(e, d) => setFilterStatus(d.selectedOptions)}
					>
						{Object.entries(Step5StatsuOptions).map(([k, v]) => (
							<Option key={k} value={v.toString()}>
								{k}
							</Option>
						))}
					</Dropdown>
					<Button appearance="primary" onClick={search}>
						Search
					</Button>
					<Button onClick={reset}>Reset</Button>
					<ActionButton appearance="primary" className="main-action" disabled={selectedRows.size === 0} onClick={convertCheck}>
						Convert and Check
					</ActionButton>
				</section>
				<Table noNativeElements ref={tableRef}>
					<TableHeader>
						<TableRow>
							<TableSelectionCell
								checked={allRowsSelected ? true : someRowsSelected ? 'mixed' : false}
								checkboxIndicator={{ 'aria-label': 'Select all rows ' }}
								onClick={toggleAllRows}
							/>
							<TableHeaderCell className="line-number" />
							<TableHeaderCell>Article ID</TableHeaderCell>
							<TableHeaderCell>Article Title</TableHeaderCell>
							<TableHeaderCell>Revision Number</TableHeaderCell>
							<TableHeaderCell>EG.Authors</TableHeaderCell>
							<TableHeaderCell>Last Publish Date</TableHeaderCell>
							<TableHeaderCell>Convert Date</TableHeaderCell>
							<TableHeaderCell>Convert Status</TableHeaderCell>
							<TableHeaderCell>Check Date</TableHeaderCell>
							<TableHeaderCell>Check Status</TableHeaderCell>
							<TableHeaderCell>Github Branch</TableHeaderCell>
							<TableHeaderCell>Github Link</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{loading ? (
							<Spinner />
						) : (
							rows.map((row, i) => (
								<TableRow key={row.id}>
									<TableSelectionCell
										checked={row.selected}
										checkboxIndicator={{ 'aria-label': 'Select row' }}
										onClick={row.onClick}
									/>
									<TableCell className="line-number">{offset + i + 1}</TableCell>
									<TableCell>
										<TableCellLayout title={row.articleSourceId} truncate>
											{row.articleSourceId}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={row.articleTitle} truncate>
											{row.articleTitle}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={row.sourceRevisionNumber} truncate>
											{row.sourceRevisionNumber}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={row.sourceAuthor} truncate>
											{row.sourceAuthor}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={FormatDate(row.sourceLastPublishDate)} truncate>
											{FormatDate(row.sourceLastPublishDate)}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={FormatDate(row.convertEndTime)} truncate>
											{renderDate(row, 'Convert')}
										</TableCellLayout>
									</TableCell>

									<TableCell>
										<TableCellLayout title="Success" truncate>
											{renderStatus(row, 'Convert')}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={FormatDate(row.checkEndTime)} truncate>
											{renderDate(row, 'Check')}
										</TableCellLayout>
									</TableCell>

									<TableCell>
										<TableCellLayout title="Failed" truncate>
											{renderStatus(row, 'Check')}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout truncate title={row.targetRepoBranch}>
											{row.targetRepoBranch}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout truncate>
											{row.targetRepoBranch ? (
												<Link
													target="_blank"
													href={`https://github.com/MicrosoftDocs/${context.Step1.RepoUrl}/blob/${row.targetRepoBranch}/${
														context.Step1.Docset.folder
													}${row.targetRepoFolder}/${encodeURIComponent(row.targetRepoFileName ?? '')}`}
													title={`${row.targetRepoFolder}/${row.targetRepoFileName}`}
												>
													{row.targetRepoFolder}/{row.targetRepoFileName}
												</Link>
											) : (
												<></>
											)}
										</TableCellLayout>
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
				<Pagination {...pagination} />
			</section>
			<section className="step-footer">
				<Button onClick={props.OnPrevious}>Previous</Button>
				<Button appearance="primary" onClick={props.OnNext}>
					Next
				</Button>
			</section>
		</section>
	)
})
