import {
	Breadcrumb,
	BreadcrumbButton,
	BreadcrumbDivider,
	BreadcrumbItem,
	Button,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from '@fluentui/react-components'
import { AddSquareRegular, ArrowRightFilled, ChevronDown24Filled, ChevronRight24Filled, ChevronRightFilled } from '@fluentui/react-icons'
import { useToggle } from '@/Hook/useToggle'
import { NewDocsetDialog } from './Dialog/NewDocset'

export const GithubSetting = () => {
	const [isNewDialogOpen, toggleNewDialogOpen, setNewDialogOpen] = useToggle(false)
	const openNewDialog = () => {
		setNewDialogOpen(true)
	}
	const items = [
		{
			id: 1,
			Name: 'Name',
			Docset: 'Docset',
			Status: 'Status',
			Create: new Date(),
			Modify: new Date(),
			ModifyBy: '87',
		},
		{
			id: 2,
			Name: 'Name',
			Docset: 'Docset',
			Status: 'Status',
			Create: new Date(),
			Modify: new Date(),
			ModifyBy: '87',
		},
		{
			id: 3,
			Name: 'Name',
			Docset: 'Docset',
			Status: 'Status',
			Create: new Date(),
			Modify: new Date(),
			ModifyBy: '87',
		},
	]
	return (
		<section>
			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbButton href="/">Home</BreadcrumbButton>
				</BreadcrumbItem>
				<BreadcrumbDivider />
				<BreadcrumbItem>
					<BreadcrumbButton href="/setting/github" current>
						Github Setting
					</BreadcrumbButton>
				</BreadcrumbItem>
			</Breadcrumb>
			<section>
				<Button icon={<AddSquareRegular />} iconPosition="before" appearance="primary" onClick={openNewDialog}>
					New Docset
				</Button>
			</section>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHeaderCell>Repository Name</TableHeaderCell>
						<TableHeaderCell>Repository URL</TableHeaderCell>
						<TableHeaderCell>Docset Name</TableHeaderCell>
						<TableHeaderCell>Docset Folder</TableHeaderCell>
						<TableHeaderCell>Permissioned Type</TableHeaderCell>
						<TableHeaderCell />
					</TableRow>
				</TableHeader>
				<TableBody></TableBody>
			</Table>
			<>
				<NewDocsetDialog open={isNewDialogOpen} />
			</>
		</section>
	)
}
