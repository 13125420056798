import { useEffect, useState } from 'react'
import { useComputed } from './useComputed'
import { Pagination } from '@components/Pagination'

export function useMemoryPagination<T>(arr: T[], size: number) {
	const [page, setPage] = useState(1)
	const offset = (page - 1) * size
	const total = arr.length
	const totalPage = Math.floor(total % size === 0 ? total / size : total / size + 1)
	const data = useComputed(() => {
		return arr.slice(offset, offset + size)
	}, [size, offset, arr])
	useEffect(() => {
		setPage(1)
	}, [arr])
	return {
		data,
		offset,
		Pagination: (
			<Pagination
				current={page}
				pageSize={size}
				total={total}
				totalPage={totalPage}
				onChange={(c) => {
					setPage(c)
				}}
				changeCurrent={setPage}
				changePageSize={() => {}}
			/>
		),
	}
}
