import './DataQueryCommandBar.scss'
import { observer } from 'mobx-react-lite'
import { Toast, ToastBody, ToastTitle, Toolbar, ToolbarButton, useToastController } from '@fluentui/react-components'
import {
	Play24Regular,
	DocumentTableSearch24Regular,
	Wrench24Regular,
	ArrowDownload24Regular,
	Save24Regular,
	Copy24Regular,
} from '@fluentui/react-icons'
import { useQueryContext } from '@components/DataQuery/DataQueryContext'
import { runInAction } from 'mobx'
import { RootConfig } from '@/Config/Const'
import { Api } from '@/Api'

export const DataQueryCommandBar = observer(() => {
	const context = useQueryContext()
	const { dispatchToast } = useToastController()
	const onSave = () => {
		if (context.DataQueryResult.Total > RootConfig.MaxQueryItem) {
			dispatchToast(
				<Toast>
					<ToastTitle>Warning</ToastTitle>
					<ToastBody>
						Please refine the query to ensure less than {RootConfig.MaxQueryItem} articles are shown in the result list.
					</ToastBody>
				</Toast>,
				{ intent: 'warning' }
			)
		}
		context.Save().then((res) => {
			dispatchToast(
				<Toast>
					<ToastTitle>Success</ToastTitle>
					<ToastBody>Saved.</ToastBody>
				</Toast>,
				{ intent: 'success' }
			)
		})
	}
	const onRun = () => {
		context.ClearSearchResult()
		context.FetchData().then((res) => {
			if (res.Count > RootConfig.MaxQueryItem) {
				dispatchToast(
					<Toast>
						<ToastTitle>Warning</ToastTitle>
						<ToastBody>
							Please refine the query to ensure less than {RootConfig.MaxQueryItem} articles are shown in the result list.
						</ToastBody>
					</Toast>,
					{ intent: 'warning' }
				)
			}
		})
	}
	const onExport = async () => {
		dispatchToast(
			<Toast>
				<ToastTitle>Information</ToastTitle>
				<ToastBody>Exporting.</ToastBody>
			</Toast>,
			{ intent: 'info' }
		)
		const articles = await context.GetExportArticles()

		context.Export(articles.Topics)
	}
	const checkDuplications = async () => {
		const duplications = await Api.MigrationTask.CheckDuplications(
			context.MigrationId!,
			context.Articles.map((a) => a['Metadata.ContentID'])
		)
		context.CurrentStep = 'AfterCheckDuplication'
		if (duplications.length !== 0) {
			dispatchToast(
				<Toast>
					<ToastTitle>Warning</ToastTitle>
					<ToastBody>There are duplicated articles.</ToastBody>
				</Toast>,
				{ intent: 'warning' }
			)

			runInAction(() => {
				duplications.forEach((duplication) => {
					context.QueryData.Includes.delete(duplication.contentId)
					context.DuplicationMap.set(duplication.contentId, duplication.migrationName)
				})
			})
		} else {
			dispatchToast(
				<Toast>
					<ToastTitle>Success</ToastTitle>
					<ToastBody>Check passed.</ToastBody>
				</Toast>,
				{ intent: 'success' }
			)
		}
	}
	return (
		<>
			<Toolbar className="sonic-toolbar">
				<ToolbarButton aria-label="Run" appearance="subtle" icon={<Play24Regular />} onClick={onRun}>
					Run
				</ToolbarButton>
				<ToolbarButton
					aria-label="Column Option"
					appearance="subtle"
					icon={<Wrench24Regular />}
					onClick={() => context.SetColumnOptionsDialogVisible(true)}
				>
					Column options
				</ToolbarButton>
				<ToolbarButton
					aria-label="Field Dictionary"
					appearance="subtle"
					icon={<DocumentTableSearch24Regular />}
					as="a"
					href="https://aka.ms/ACCTFieldDictionary"
					target="_blank"
				>
					Field Dictionary
				</ToolbarButton>
				<ToolbarButton aria-label="Export Result" appearance="subtle" icon={<ArrowDownload24Regular />} onClick={onExport}>
					Export Result
				</ToolbarButton>
				<ToolbarButton aria-label="Check duplication" appearance="subtle" icon={<Copy24Regular />} onClick={checkDuplications}>
					Check duplication
				</ToolbarButton>
				<ToolbarButton
					aria-label="Save"
					appearance="subtle"
					onClick={onSave}
					icon={<Save24Regular />}
					disabled={context.CurrentStep !== 'AfterCheckDuplication'}
				>
					Save
				</ToolbarButton>
			</Toolbar>
		</>
	)
})
