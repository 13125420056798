const IsDev = process.env.NODE_ENV === 'development'
const noop = () => {}
const WhenDev = <T extends (...args: any) => any>(func: T): T => {
	return IsDev ? func : (noop as T)
}
const StorgeGlobal = WhenDev((key: string, value: unknown, target: any = window) => {
	target[key] = value
})
export const Debug = {
	StorgeGlobal,
	WhenDev,
	IsDev,
} as const
