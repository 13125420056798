/* eslint-disable react-hooks/exhaustive-deps */
import { Api } from '@/Api'
import { Docset } from '@/Api/Types'
import { Dropdown, OptionOnSelectData, SelectionEvents, Option } from '@fluentui/react-components'
import { useRequest } from 'ahooks'
import { useMemo } from 'react'

type Props = {
	OnSelect(val: Docset): void
	Value: Docset
	RepoUrl: string
}
export const DocsetSelect = (props: Props) => {
	const { data: docsets, loading } = useRequest(
		() => {
			if (props.RepoUrl) return Api.Github.ReadDocsets(props.RepoUrl)
			else return Promise.resolve([])
		},
		{ refreshDeps: [props.RepoUrl] }
	)
	const docsetOptions = useMemo(() => {
		return (docsets ?? []).map((docset) => ({
			value: docset.folder,
			text: `${docset.name}  (${docset.permissionedType})`,
		}))
	}, [docsets])
	const value = useMemo(() => {
		return docsetOptions.find((v) => v.value === props.Value?.folder)?.text ?? ''
	}, [props.Value?.folder ?? '', docsets])
	const onOptionSelect = (event: SelectionEvents, data: OptionOnSelectData) => {
		const folder = docsetOptions.find((v) => v.text === data.optionText)!.value
		props.OnSelect(docsets!.find((v) => v.folder === folder)!)
	}
	return (
		<Dropdown value={value} onOptionSelect={onOptionSelect}>
			{loading ? (
				<Option disabled>Loading</Option>
			) : docsetOptions.length === 0 ? (
				<Option disabled>No Option</Option>
			) : (
				docsetOptions.map((docset) => (
					<Option key={docset.value} value={docset.value}>
						{docset.text}
					</Option>
				))
			)}
		</Dropdown>
	)
}
