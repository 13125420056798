import { Env } from '@env'
import { Fetch } from './Fetch'
import { Docsets } from './Types'
const Root = `${Env.BackendApiUrl}api/migration/GitHubRepo`
const ReadDocsets = (repoUrl: string) => {
	return Fetch.Get<Docsets>(`${Root}/${repoUrl}/Docsets`)
}
const ListRepositories = (contains: string) => {
	return Fetch.Get<string[]>({ url: Root, query: { contains } })
}
const ListTocs = (repoName: string, docset: string) => {
	return Fetch.Get<string[]>({ url: `${Root}/${repoName}/toc`, query: { docset } })
}
export const Github = {
	ReadDocsets,
	ListRepositories,
	ListTocs,
} as const
