import { saveAs } from 'file-saver'

/**
 * export run result data
 * @param taskRunsResultContent
 * @param fileName
 */
export const exportTaskRunsResults = (taskRunsResultContent: string, fileName: string) => {
	const test = new Blob([taskRunsResultContent], {
		type: 'text/csv;charset=utf-8',
	})

	saveAs(test, fileName)
}

/**
 * export csv file from Curation Filter Editor
 * @param dataContent
 * @param fileName
 */
export const exportCurationFilterData = (dataContent: string, fileName: string) => {
	const test = new Blob([dataContent], {
		type: 'text/csv;charset=utf-8',
	})

	saveAs(test, fileName)
}
