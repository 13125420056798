/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	createTableColumn,
	Dropdown,
	Input,
	Option,
	SkeletonItem,
	Spinner,
	Table,
	TableBody,
	TableCell,
	TableCellLayout,
	TableHeader,
	TableHeaderCell,
	TableRow,
	TableSelectionCell,
	Toast,
	ToastBody,
	ToastTitle,
	useTableColumnSizing_unstable,
	useTableFeatures,
	useTableSelection,
	useToastController,
} from '@fluentui/react-components'
import { StepProps } from '..'
import { useMigrationContext } from '../Context'
import { useEffect, useState } from 'react'
import { EStep3Status, MigrationActionStatus, MigrationItem, MigrationStep, Step3StatsuOptions } from '@/Api/Types'
import { stringify } from '@/Utilities/Stringify'
import { observer } from 'mobx-react-lite'
import { Api } from '@/Api'
import { FormatDate, ToMap } from '@/Utilities'
import { CheckFailDialog } from './Components/CheckFailDialog'
import { RetrieveFailDialog } from './Components/RetrieveFailDialog'
import { ActionButton } from '@components/ActionButton'
import { usePagination } from 'ahooks'
import { Pagination } from '@components/Pagination'
import { runInAction } from 'mobx'
export const Step3 = observer((props: StepProps) => {
	const context = useMigrationContext()
	const step3 = context.Step3
	const [columnsDef] = useState(
		[
			'EG.Article ID',
			'EG.Article Title',
			'EG.Revision Number',
			'EG.Authors',
			'Retrieve Status',
			'Retrieve Date',
			'Check Status',
			'Check Date',
		].map((i) => createTableColumn<MigrationItem>({ columnId: i }))
	)

	const [articleId, setArticleId] = useState('')
	const [title, setTitle] = useState('')
	const [filterStatus, setFilterStatus] = useState([] as string[])

	const { data, loading, runAsync, pagination } = usePagination(
		(param) => {
			return Api.MigrationTask.GetItems(
				context.MigrationId!,
				{
					articleId,
					title,
					status: filterStatus.map((o) => Number(o) as EStep3Status),
					onlyIncluded: true,
				},
				{
					pageNum: param.current,
					pageSize: param.pageSize,
				}
			)
		},
		{ defaultPageSize: 10 }
	)
	const search = () => {
		runAsync({
			current: 1,
			pageSize: 10,
		})
	}
	const reset = () => {
		setArticleId('')
		setTitle('')
		setFilterStatus([])
		requestIdleCallback(() => {
			runAsync({
				current: 1,
				pageSize: 10,
			})
		})
	}
	useEffect(() => {
		if (data && data.list.length !== 0) {
			Api.MigrationTask.GetItemStatusAndDates(data.list.map((i) => i.id)).then((res) => {
				step3.StatusMap = ToMap(
					res,
					(i) => i.id,
					(i) => i.actions
				)
			})
		}
	}, [data])
	const {
		getRows,
		tableRef,
		selection: { allRowsSelected, someRowsSelected, toggleAllRows, toggleRow, isRowSelected, selectedRows, deselectRow },
	} = useTableFeatures(
		{
			columns: columnsDef,
			items: data?.list ?? [],
			getRowId(item) {
				return item.id
			},
		},
		[
			useTableColumnSizing_unstable({}),
			useTableSelection({
				selectionMode: 'multiselect',
				defaultSelectedItems: [],
			}),
		]
	)
	const rows = getRows(({ item, rowId }) => {
		const selected = isRowSelected(rowId)
		const status = step3.Status.get(item.id)
		return {
			item: item,
			rowId: rowId,
			...item,
			onClick: (e: React.MouseEvent) => toggleRow(e, rowId),
			selected,
			appearance: selected ? ('brand' as const) : ('none' as const),
			retrieveStatus: status?.RetrieveStatus ?? MigrationActionStatus.Pending,
			retrieveEndTime: status?.RetrieveEndTime ?? '',
			retrieveError: status?.RetrieveError,
			checkStatus: status?.CheckStatus ?? MigrationActionStatus.Pending,
			checkEndTime: status?.CheckEndTime ?? '',
			checkError: status?.CheckError,
			passed: status?.RetrieveStatus === 'Success' && status?.CheckStatus === 'Success',
		}
	})
	const renderDate = (row: (typeof rows)[number], statusType: 'Retrieve' | 'Check') => {
		switch (row[statusType === 'Retrieve' ? 'retrieveStatus' : 'checkStatus']) {
			case 'Processing':
				return <SkeletonItem />
			case 'Failed':
			case 'Success':
				return FormatDate(row[statusType === 'Retrieve' ? 'retrieveEndTime' : 'checkEndTime'])
		}
	}
	const renderStatus = (row: (typeof rows)[number], statusType: 'Retrieve' | 'Check') => {
		switch (row[statusType === 'Retrieve' ? 'retrieveStatus' : 'checkStatus']) {
			case 'Pending':
				return <Button appearance="transparent">Pending</Button>
			case 'Processing':
				return <SkeletonItem />
			case 'Failed':
				return statusType === 'Retrieve' ? <RetrieveFailDialog message={row.retrieveError} /> : <CheckFailDialog item={row} />
			case 'Success':
				return <Button appearance="transparent">Success</Button>
		}
	}
	const getStatusLoop = (ids: number[]) => {
		Api.MigrationTask.GetItemStatusAndDates(ids, [MigrationStep.ArticleSync, MigrationStep.ContentCheck]).then((res) => {
			runInAction(() => {
				res.forEach((val) => {
					step3.StatusMap.set(val.id, val.actions)
				})
			})
			const notFinished = new Set(ids)
			res.forEach((item) => {
				if (
					item.actions[MigrationStep.ContentCheck]?.status === MigrationActionStatus.Success ||
					item.actions[MigrationStep.ContentCheck]?.status === MigrationActionStatus.Failed ||
					item.actions[MigrationStep.ArticleSync]?.status === MigrationActionStatus.Failed
				) {
					notFinished.delete(item.id)
					deselectRow({} as any, item.id)
				}
			})
			if (notFinished.size !== 0) {
				window.setTimeout(() => getStatusLoop([...notFinished]), 1000)
			}
		})
	}
	const { dispatchToast } = useToastController()
	const retrieveCheck = () => {
		const ids = [...selectedRows] as number[]
		dispatchToast(
			<Toast>
				<ToastTitle>Info</ToastTitle>
				<ToastBody>In progress.</ToastBody>
			</Toast>,
			{ intent: 'info' }
		)
		return Api.MigrationTask.RetrieveAndCheck(ids).then(() => {
			window.setTimeout(() => getStatusLoop(ids), 1000)
		})
	}
	const onNext = () => {
		context.TaskStatus.retrieved = 1
		props.OnNext()
	}
	const offset = (pagination.current - 1) * pagination.pageSize
	const canNext = (() => {
		if (!data || !data.list) return false
		return data.list.some((item) => {
			const status = step3.Status.get(item.id)
			return status?.CheckStatus === 'Success'
		})
	})()
	return (
		<section className="step step-3">
			<section className="step-body">
				<section className="step-filter">
					<Input placeholder="Article Id" value={articleId} onChange={(e, d) => setArticleId(d.value)} />
					<Input placeholder="Title" value={title} onChange={(e, d) => setTitle(d.value)} />
					<Dropdown
						selectedOptions={filterStatus}
						multiselect
						placeholder="Status"
						onOptionSelect={(e, d) => {
							setFilterStatus(d.selectedOptions)
						}}
					>
						{Object.entries(Step3StatsuOptions).map(([k, v]) => (
							<Option key={k} value={v.toString()}>
								{k}
							</Option>
						))}
					</Dropdown>
					<Button appearance="primary" onClick={search}>
						Search
					</Button>
					<Button onClick={reset}>Reset</Button>
					<ActionButton appearance="primary" className="main-action" disabled={selectedRows.size === 0} onClick={retrieveCheck}>
						Retrieve and Check
					</ActionButton>
				</section>
				<Table noNativeElements ref={tableRef}>
					<TableHeader>
						<TableRow>
							<TableSelectionCell
								checked={allRowsSelected ? true : someRowsSelected ? 'mixed' : false}
								checkboxIndicator={{ 'aria-label': 'Select all rows ' }}
								onClick={toggleAllRows}
							/>
							<TableHeaderCell className="line-number" />
							<TableHeaderCell>Article ID</TableHeaderCell>
							<TableHeaderCell>Article Title</TableHeaderCell>
							<TableHeaderCell>Revision Number</TableHeaderCell>
							<TableHeaderCell>EG.Authors</TableHeaderCell>
							<TableHeaderCell>Retrieve Date</TableHeaderCell>
							<TableHeaderCell>Retrieve Status</TableHeaderCell>
							<TableHeaderCell>Check Date</TableHeaderCell>
							<TableHeaderCell>Check Status</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{loading ? (
							<Spinner />
						) : (
							rows.map((row, i) => (
								<TableRow key={row.id}>
									<TableSelectionCell
										checked={row.selected}
										checkboxIndicator={{ 'aria-label': 'Select row' }}
										onClick={row.onClick}
									/>
									<TableCell className="line-number">{offset + i + 1}</TableCell>
									<TableCell>
										<TableCellLayout title={row.articleSourceId} truncate>
											{row.articleSourceId}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={row.articleTitle} truncate>
											{row.articleTitle}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={stringify(row.articleRevisionNumber ?? 0)} truncate>
											{row.articleRevisionNumber ?? 0}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={row.articleAuthor} truncate>
											{row.articleAuthor}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={FormatDate(row.retrieveEndTime)} truncate>
											{renderDate(row, 'Retrieve')}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={row.retrieveStatus} truncate>
											{renderStatus(row, 'Retrieve')}
										</TableCellLayout>
									</TableCell>

									<TableCell>
										<TableCellLayout title={FormatDate(row.checkEndTime)} truncate>
											{renderDate(row, 'Check')}
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<TableCellLayout title={row.checkStatus} truncate>
											{renderStatus(row, 'Check')}
										</TableCellLayout>
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
				<Pagination {...pagination} />
			</section>
			<section className="step-footer">
				<Button onClick={props.OnPrevious}>Previous</Button>
				<ActionButton appearance="primary" onClick={onNext} disabled={!canNext}>
					Next
				</ActionButton>
			</section>
		</section>
	)
})
