import { useEffect } from 'react'
import { DialogProvider } from './Components/DialogProvider'
import { DataQueryBuilder } from './Components/DataQueryBuilder'
import { DataQueryCommandBar } from './Components/DataQueryCommandBar'
import { DataQueryResult } from './Components/DataQueryResult'

import './DataQuery.css'
import { observer } from 'mobx-react-lite'
import { useQueryContext } from './DataQueryContext'
import { Debug } from '@/Utilities/Debug'
import { toJS } from 'mobx'

//MetaDataQueryState
export const SonicQuery = observer(() => {
	const context = useQueryContext()
	useEffect(() => {
		context.InitState()
	}, [context])
	Debug.StorgeGlobal('Exp', toJS(context.expression))
	return (
		<div className="Metadata-Query-Container">
			<DialogProvider />
			<DataQueryCommandBar />
			<DataQueryBuilder />
			<DataQueryResult />
		</div>
	)
})
