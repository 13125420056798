/* eslint-disable react-hooks/exhaustive-deps */
import { TabList, Tab, SelectTabData, SelectTabEvent, Title3, Body1, Spinner, Subtitle2 } from '@fluentui/react-components'
import { useEffect, useState } from 'react'
import { Step1 } from './Step/step-1'
import './NewMigration.scss'
import { Step2 } from './Step/step-2'
import { Context, NewMigrationContext, steps, Steps } from './Context'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Step3 } from './Step/step-3'
import { autorun } from 'mobx'
import { Step4 } from './Step/step-4'
import { Step5 } from './Step/step-5'
import { Step6 } from './Step/step-6'
export type StepProps = {
	OnNext: () => void
	OnPrevious: () => void
}

const components = {
	'Config the migration': Step1,
	'Select articles': Step2,
	'Retrieve articles': Step3,
	'Config articles': Step4,
	'Convert articles': Step5,
	'Publish articles': Step6,
}
export const NewMigration = observer(() => {
	const [currentStep, setStep] = useState<Steps>('Config the migration')
	const routerParams = useParams() as { migrationId?: string }
	const context = useLocalObservable(() => new NewMigrationContext(routerParams))
	const navigate = useNavigate()
	let [searchParams, setSearchParams] = useSearchParams()
	const findStepIndex = (step: Steps) => steps.indexOf(step)
	useEffect(() => {
		const index = findStepIndex(searchParams.get('step') as Steps)
		if (index === -1) {
			setSearchParams(
				{
					step: steps[0],
				},
				{ replace: true }
			)
		} else setStep(steps[index])
	}, [searchParams])
	const navigateToStep = (step: Steps) => {
		setSearchParams(
			{
				step: step,
			},
			{ replace: true }
		)
	}
	const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
		navigateToStep(data.value as Steps)
	}
	const onNext = () => {
		const index = findStepIndex(currentStep)
		navigateToStep(steps[index + 1])
	}
	const onPrevious = () => {
		const index = findStepIndex(currentStep)
		navigateToStep(steps[index - 1])
	}
	const Component = components[currentStep]
	const shouldDisable = (step: Steps) => {
		switch (step) {
			case 'Config the migration':
				return false
			case 'Select articles':
				return context.MigrationId === null
			case 'Retrieve articles':
				return context.TaskStatus.included === 0
			case 'Config articles':
				return context.TaskStatus.retrieved === 0
			case 'Convert articles':
				return context.TaskStatus.configured === 0
			case 'Publish articles':
				return context.TaskStatus.converted === 0
		}
		return true
	}
	useEffect(() => {
		return autorun(() => {
			if (context.MigrationId && !routerParams.migrationId) {
				navigate(`/migration/${context.MigrationId}`, {
					replace: true,
				})
			}
		})
	}, [])
	return (
		<Context.Provider value={context}>
			<Subtitle2 className="migration-title">
				<span>
					{routerParams.migrationId ? 'Current' : 'New'} Migration - {currentStep}
				</span>
				<span className="main-action">{context.Step1.Name ?? ''}</span>
			</Subtitle2>
			<TabList selectedValue={currentStep} onTabSelect={onTabSelect}>
				{steps.map((step, i) => (
					<Tab key={step} value={step} disabled={shouldDisable(step)}>
						<Body1>{`${i + 1}. ${step}`}</Body1>
					</Tab>
				))}
			</TabList>
			{!context.FinishLoading ? <Spinner /> : <Component OnNext={onNext} OnPrevious={onPrevious} />}
		</Context.Provider>
	)
})
