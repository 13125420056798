import { Api } from '@/Api'
import { EPermissionType, MigrationArticle } from '@/Api/Types'
import { makeAutoObservable, runInAction } from 'mobx'
import { NewMigrationContext } from '.'
type Article = MigrationArticle & { fileName?: string }
export class Step4Context {
	public constructor(root: NewMigrationContext) {
		this.articleMap = new Map()
		this.root = root
		makeAutoObservable(this)
	}
	public CanNext(articles: MigrationArticle[]) {
		if (articles.length !== this.articleMap.size) return false
		return articles.every((a) => {
			const entity = this.articleMap.get(a.id)
			if (entity === undefined) return false
			return entity.service && entity.topic && entity.permission && entity.name && entity.folder
		})
	}
	public get Map() {
		return this.articleMap
	}
	public async Save() {
		if (this.articleMap.size === 0) return
		await Api.MigrationArticle.ConfigArticle(
			[...this.articleMap.values()]
				.filter((e) => {
					return !e.readonly
				})
				.map((entity) => {
					let name = entity.name.trim()
					if (name.length && !name.toLowerCase().endsWith('.md')) name = `${name}.md`
					return {
						id: entity.id,
						msService: entity.service,
						msTopic: entity.topic,
						permissionType: entity.permission,
						targetRepoFileName: name,
						targetRepoFolder: entity.folder,
					}
				})
		)
		runInAction(() => {
			this.root.TaskStatus.configured = 1
		})
	}
	public setTopic(row: MigrationArticle, val: string) {
		const entity = this.GetEntity(row)
		entity.topic = val
	}
	public setService(row: MigrationArticle, val: string) {
		const entity = this.GetEntity(row)
		entity.service = val
	}
	public setPermissionType(row: MigrationArticle, val: EPermissionType) {
		const entity = this.GetEntity(row)
		entity.permission = val
	}
	public setFolder(row: MigrationArticle, val: string) {
		const entity = this.GetEntity(row)
		entity.folder = val
	}
	public setName(row: Article, val: string) {
		const regex = /[^a-z0-9-]/g
		const entity = this.GetEntity(row)

		if (regex.test(val)) {
			entity.showPopover = true
			return
		}
		entity.showPopover = false
		entity.name = val
	}
	public GetEntity(row: MigrationArticle) {
		let entity = this.articleMap.get(row.id)
		if (entity === undefined) {
			this.articleMap.set(row.id, {
				topic: '',
				service: '',
				folder: '',
				name: '',
				permission: this.root.Step1.DefaultPermissionType,
				showPopover: false,
				readonly: false,
				id: row.id,
			})
			entity = this.articleMap.get(row.id)!
		}
		return entity
	}
	private articleMap: Map<
		number,
		{
			id: number
			folder: string
			topic: string
			service: string
			permission: EPermissionType
			name: string
			showPopover: boolean
			readonly: boolean
		}
	>
	private root: NewMigrationContext
}
