import { observer } from 'mobx-react-lite'
import { useMigrationContext } from '../Context'
import { FormItem } from '@components/FormItem'
import {
	Button,
	InfoLabel,
	Input,
	Link,
	Radio,
	RadioGroup,
	Textarea,
	Toast,
	ToastBody,
	ToastTitle,
	useToastController,
} from '@fluentui/react-components'
import { SearchSelect } from './Components/SearchSelect'
import { DocsetSelect } from './Components/DocsetSelect'
import { msalInstance } from '@/index'
import { StepProps } from '..'
import { Api } from '@/Api'
import { EPermissionType, MigrationTaskModel, PermissionType } from '@/Api/Types'
import { OperationResult } from '@/Api/OperationResult'
import { stringify } from 'flatted'
import { ActionButton } from '@components/ActionButton'
import { useNavigate } from 'react-router-dom'
export const Step1 = observer((props: StepProps) => {
	const context = useMigrationContext()
	const Step1 = context.Step1
	const userAccount = msalInstance.getActiveAccount()!
	const { dispatchToast } = useToastController()
	const navigate = useNavigate()
	const create = async () => {
		const model: MigrationTaskModel = {
			migrationName: Step1.Name,
			permissionType: Step1.DefaultPermissionType,
			targetDocSetUrl: Step1.Docset.folder,
			targetDocSetName: Step1.Docset.name,
			description: Step1.Description,
			defaultDisclaimer: Step1.Disclaimer,
			ownerMail: userAccount.username,
			createdBy: userAccount.username,
			targetRepoUrl: Step1.RepoUrl,
			articleFilter: JSON.stringify({
				Expression: stringify(context.Step2.Expression),
				...context.Step2.QueryData,
			}),
		}
		await Api.MigrationTask.CreateMigration(model)
			.then((migrationId) => {
				dispatchToast(
					<Toast>
						<ToastTitle>Success</ToastTitle>
						<ToastBody>Migration created</ToastBody>
					</Toast>,
					{ intent: 'success' }
				)
				Step1.IsDirty = false
				context.MigrationId = migrationId
				props.OnNext()
			})
			.catch((reason: OperationResult<unknown>) => {
				if (reason.errorCode === 6002)
					dispatchToast(
						<Toast>
							<ToastTitle>Error</ToastTitle>
							<ToastBody>{reason.errorMessage}</ToastBody>
						</Toast>,
						{ intent: 'error' }
					)
			})
	}
	const modify = async (migrationId: number) => {
		const model: MigrationTaskModel = {
			migrationName: Step1.Name,
			permissionType: Step1.DefaultPermissionType,
			targetDocSetUrl: Step1.Docset.folder,
			targetDocSetName: Step1.Docset.name,
			description: Step1.Description,
			defaultDisclaimer: Step1.Disclaimer,
			ownerMail: userAccount.username,
			createdBy: userAccount.username,
			targetRepoUrl: Step1.RepoUrl,
			articleFilter: JSON.stringify({
				Expression: stringify(context.Step2.Expression),
				...context.Step2.QueryData,
			}),
		}
		await Api.MigrationTask.ModifyMigration(migrationId, model)
			.then((migrationId) => {
				dispatchToast(
					<Toast>
						<ToastTitle>Success</ToastTitle>
						<ToastBody>Migration modified.</ToastBody>
					</Toast>,
					{ intent: 'success' }
				)
				Step1.IsDirty = false
				props.OnNext()
			})
			.catch((reason: OperationResult<unknown>) => {
				if (reason.errorCode === 6002)
					dispatchToast(
						<Toast>
							<ToastTitle>Error</ToastTitle>
							<ToastBody>{reason.errorMessage}</ToastBody>
						</Toast>,
						{ intent: 'error' }
					)
			})
	}
	const onNext = async () => {
		if (!Step1.IsDirty) {
			props.OnNext()
			return
		}
		if (context.MigrationId) await modify(context.MigrationId)
		else await create()
	}
	const onCancel = () => {
		navigate(-1)
	}
	return (
		<section className="step">
			<section className="step-body">
				<section className="grid-gap-large">
					<FormItem store={Step1} prop="Name" label="Migration Name" required>
						{({ value, valueChange, onBlur }) => (
							<Input maxLength={200} value={value} onChange={(e, v) => valueChange(v.value)} onBlur={onBlur} autoComplete="off" />
						)}
					</FormItem>
				</section>
				<section className="grid-2 grid-gap-large">
					<FormItem store={Step1} prop="Description" label="Migration Description" required>
						{({ value, valueChange, onBlur }) => (
							<Textarea maxLength={500} value={value} onChange={(e, v) => valueChange(v.value)} onBlur={onBlur} autoComplete="off" />
						)}
					</FormItem>
					<FormItem store={Step1} prop="Disclaimer" label="Disclaimer">
						{({ value, valueChange, onBlur }) => (
							<Textarea value={value} onChange={(e, v) => valueChange(v.value)} onBlur={onBlur} autoComplete="off" />
						)}
					</FormItem>
				</section>
				<section>
					<FormItem
						store={Step1}
						prop="DefaultPermissionType"
						label={
							<InfoLabel
								info={
									<Link
										target="_blank"
										href="https://review.learn.microsoft.com/en-us/help/platform/permissioned-overview?branch=main"
									>
										Permissioned content overview
									</Link>
								}
							>
								Default Permission Type
							</InfoLabel>
						}
						required
					>
						{({ value, valueChange, onBlur }) => (
							<RadioGroup
								layout="horizontal"
								value={value}
								onBlur={onBlur}
								onChange={(_, data) => valueChange(data.value as EPermissionType)}
							>
								<Radio value={PermissionType.Public} label={PermissionType.Public} />
								<Radio value={PermissionType.Private} label={PermissionType.Private} />
								<Radio value={PermissionType.Secret} label={PermissionType.Secret} />
							</RadioGroup>
						)}
					</FormItem>
				</section>
				<section className="grid-2 grid-gap-large">
					<FormItem store={Step1} prop="RepoUrl" label="Select the Repository" required>
						{({ value, valueChange }) => <SearchSelect Value={value} OnSelect={valueChange} />}
					</FormItem>
					<FormItem store={Step1} prop="Docset" label="Select the Docset" required>
						{({ value, valueChange }) => <DocsetSelect Value={value} OnSelect={valueChange} RepoUrl={Step1.RepoUrl} />}
					</FormItem>
				</section>
			</section>
			<section className="step-footer">
				{!context.MigrationId && <Button onClick={onCancel}>Cancel</Button>}
				<ActionButton appearance="primary" disabled={!Step1.CanNext} onClick={onNext}>
					Next
				</ActionButton>
			</section>
		</section>
	)
})
