import { Api } from '@/Api'
import { Docset, Docsets } from '@/Api/Types'
import { FormItem } from '@components/FormItem'
import {
	Button,
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Field,
	InfoLabel,
	Input,
	Link,
	Select,
	SelectOnChangeData,
	Textarea,
} from '@fluentui/react-components'
import { debounce } from 'lodash'
import { autorun } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
type Prop = {
	open: boolean
}

export const NewDocsetDialog = observer((prop: Prop) => {
	const [docsets, setDocsets] = useState([] as Docsets)
	const state = useLocalObservable(() => ({
		repoName: '',
		get RepoName() {
			return this.repoName
		},
		set RepoName(val: string) {
			this.repoName = val
		},
		repoUrl: '',
		get RepoUrl() {
			return this.repoUrl
		},
		set RepoUrl(val: string) {
			this.repoUrl = val
		},
		pat: '',
		get Pat() {
			return this.pat
		},
		set Pat(val: string) {
			this.pat = val
		},
		docset: { name: '', folder: '', permissionedType: '' } as Docset,
		set Docset(val: Docset) {
			this.docset = val
		},
		docsetDescription: '',
		get DocsetDescription() {
			return this.docsetDescription
		},
		set DocsetDescription(val: string) {
			this.docsetDescription = val
		},
	}))

	const fetchDocset = useMemo(
		() =>
			debounce(
				() =>
					Api.Github.ReadDocsets(state.repoUrl).then((v) => {
						setDocsets(v)
						if (v.length === 1) {
							state.Docset = v[0]
						}
					}),
				300
			),
		[state]
	)
	useEffect(() => {
		const cleaner = autorun(() => {
			if (state.pat && state.repoUrl) {
				fetchDocset()
			}
		})
		return cleaner
	}, [])
	const patLabel = useMemo(
		() => (
			<InfoLabel
				info={
					<>
						<span>
							Generated from
							<Link href="https://github.com/settings/tokens?type=beta" target="_blank">
								Github PAT
							</Link>
						</span>
						<br />
						<span>Learn More.</span>
					</>
				}
			>
				Github Access Token
			</InfoLabel>
		),
		[]
	)
	const onDocsetChange = (ev: React.ChangeEvent<HTMLSelectElement>, data: SelectOnChangeData) => {}
	return (
		<Dialog open={prop.open}>
			<DialogSurface style={{ maxWidth: '1000px' }}>
				<DialogBody>
					<DialogTitle>New Docset</DialogTitle>
					<DialogContent>
						<section className="grid-2">
							<FormItem store={state} prop="RepoName" label="Github Repository Name" required>
								{({ value, valueChange, onBlur }) => (
									<Input value={value} onChange={(e, v) => valueChange(v.value)} onBlur={onBlur} />
								)}
							</FormItem>
							<FormItem store={state} prop="RepoUrl" label="Github Repository URL" required>
								{({ value, valueChange, onBlur }) => (
									<Input
										value={value}
										contentBefore="https://github.com/MicrosoftDocs/"
										onChange={(e, v) => valueChange(v.value)}
										onBlur={onBlur}
									/>
								)}
							</FormItem>
						</section>
						<section>
							<FormItem store={state} prop="Pat" label={patLabel} required>
								{({ value, valueChange, onBlur }) => (
									<Input value={value} onChange={(e, v) => valueChange(v.value)} onBlur={onBlur} />
								)}
							</FormItem>
						</section>
						<section className="grid-3">
							<Field label="Docset Name" required>
								<Select disabled={docsets.length === 0} value={state.docset.name} onChange={onDocsetChange}>
									{docsets.map((docset) => (
										<option value={docset.folder} key={docset.name}>
											{docset.name}
										</option>
									))}
								</Select>
							</Field>
							<Field label="Docset Folder" required>
								<Input disabled value={state.docset.folder} />
							</Field>
							<Field label="Docset Permissioned Type" required>
								<Select disabled value={state.docset.permissionedType}>
									<option>{state.docset.permissionedType}</option>
								</Select>
							</Field>
						</section>
						<section>
							<FormItem store={state} prop="DocsetDescription" label="Docset Description">
								{({ value, valueChange, onBlur }) => <Textarea value={value} onChange={(e, v) => valueChange(v.value)} />}
							</FormItem>
						</section>
					</DialogContent>

					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary">Close</Button>
						</DialogTrigger>
						<Button appearance="primary">Save</Button>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
})
