import './DataQueryResult.scss'
import { observer } from 'mobx-react-lite'
import { useQueryContext } from '@components/DataQuery/DataQueryContext'
import {
	Body1,
	Body1Strong,
	createTableColumn,
	Link,
	Spinner,
	Table,
	TableBody,
	TableCell,
	TableCellLayout,
	TableColumnSizingOptions,
	TableHeader,
	TableHeaderCell,
	TableRow,
	useTableColumnSizing_unstable,
	useTableFeatures,
} from '@fluentui/react-components'
import { ArticleData } from '@components/DataQuery/DataQueryContext/ArticleData'
import { useComputed } from '@/Hook/useComputed'
import { DocumentTextLink20Regular } from '@fluentui/react-icons'
import { stringify } from '@/Utilities/Stringify'
import { action } from 'mobx'
import { EReviewStatus, ReviewStatus } from '@/Api/Types'
import { PreviewAction, ReviewResult } from './PreviewArticle'
import { useState } from 'react'
import { useMemoryPagination } from '@/Hook/useMemoryPagination'
import { DynamicColumn } from '@components/DataQuery/DataQueryContext/DataQueryResult'
import { FormatDate } from '@/Utilities'

const LineColumnId = '_Link'
const LineNumberId = '_LineNumber'
const StatusId = '_ReviewStatus'
const accessData = (article: ArticleData, path: string) => {
	const p = (path ?? '').replaceAll(' > ', '.')
	let root = article as any
	return root[p]
}
export const DataQueryResult = observer(() => {
	const context = useQueryContext()
	const columns = context.DataQueryResult.Columns
	const columnsDef = useComputed(() => {
		return [
			createTableColumn<ArticleData>({
				columnId: StatusId,
			}),
			createTableColumn<ArticleData>({
				columnId: LineColumnId,
			}),
			...context.DataQueryResult.Columns.map((column) =>
				createTableColumn<ArticleData>({
					columnId: column.Props,
				})
			),
		]
	})
	const columnSizing = useComputed(() => {
		return {
			[LineColumnId]: {
				defaultWidth: 30,
				minWidth: 30,
			},
			[LineNumberId]: {
				defaultWidth: 20,
				minWidth: 20,
			},
			[StatusId]: {
				defaultWidth: 180,
				minWidth: 180,
			},
			...Object.fromEntries(
				context.DataQueryResult.Columns.map((column) => [
					column.Props,
					{
						defaultWidth: column.Width,
						minWidth: 50,
					},
				])
			),
		} as TableColumnSizingOptions
	})
	const { offset, data, Pagination } = useMemoryPagination(context.Articles, 10)
	const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
		{
			columns: columnsDef,
			items: data,
		},
		[
			useTableColumnSizing_unstable({
				columnSizingOptions: columnSizing,
				autoFitColumns: false,
			}),
		]
	)
	const rows = getRows(({ item, rowId }) => {
		return {
			rowId,
			item,
			status: context.GetRowReviewStatus(item['Metadata.ContentID']),
		}
	})
	const [previewRow, setPreviewRow] = useState<ArticleData | null>(null)
	const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: ArticleData) => {
		e.preventDefault()
		e.stopPropagation()
		setPreviewRow(item)
	}
	const onDialogClose = (value: ReviewResult) => {
		if (value !== null && previewRow !== null) {
			if (value === ReviewResult.Exclude) setReviewStatus(previewRow, ReviewStatus.Exclude)
			else if (value === ReviewResult.Include) setReviewStatus(previewRow, ReviewStatus.Include)
			else if (value === ReviewResult.Reset) setReviewStatus(previewRow, ReviewStatus.NotReviewed)
		}
		setPreviewRow(null)
	}
	const setReviewStatus = action((article: ArticleData, status: EReviewStatus) => {
		if (status !== ReviewStatus.Exclude && status !== ReviewStatus.Include && status !== ReviewStatus.NotReviewed) return
		const contentId = article['Metadata.ContentID']
		context.QueryData.Includes.delete(contentId)
		context.QueryData.Excludes.delete(contentId)
		if (status !== ReviewStatus.NotReviewed) context.DirtyArticle.set(contentId, article)
		if (status === ReviewStatus.Include) {
			context.QueryData.Includes.add(contentId)
		} else if (status === ReviewStatus.Exclude) {
			context.QueryData.Excludes.add(contentId)
		}
		context.CurrentStep = 'AfterRun'
	})
	const renderCell = (item: ArticleData, column: DynamicColumn) => {
		const value = accessData(item, column.DisplayName)
		if (typeof value !== 'string') return value
		if (value.startsWith('http'))
			return (
				<Link href={value} target="_blank">
					{value}
				</Link>
			)
		if (column.DisplayName.toLocaleLowerCase().endsWith('date')) {
			if (value) return FormatDate(value)
		}
		return value
	}
	return (
		<section className="data-query-result">
			<Table
				className="data-query-table"
				ref={tableRef}
				{...columnSizing_unstable.getTableProps({ style: { minWidth: 'unset' } })}
				noNativeElements
			>
				<TableHeader>
					<TableRow>
						<TableHeaderCell className="line-number"></TableHeaderCell>
						<TableHeaderCell {...columnSizing_unstable.getTableHeaderCellProps(LineColumnId)}></TableHeaderCell>
						<TableHeaderCell {...columnSizing_unstable.getTableHeaderCellProps(StatusId)}>Review Status</TableHeaderCell>

						{context.DataQueryResult.Columns.map((column) => (
							<TableHeaderCell key={column.Props} {...columnSizing_unstable.getTableHeaderCellProps(column.Props)}>
								{column.DisplayName}
							</TableHeaderCell>
						))}
					</TableRow>
				</TableHeader>

				{context.DataQueryResult.Loading ? (
					<section className="data-query-spinner">
						<Spinner />
					</section>
				) : rows.length === 0 ? (
					<Body1>No results match the query.</Body1>
				) : (
					<TableBody>
						{rows.map(({ item, status }, i) => (
							<TableRow key={item['Metadata.ContentID']}>
								<TableCell className="line-number">{offset + i + 1}</TableCell>

								<TableCell {...columnSizing_unstable.getTableCellProps(LineColumnId)}>
									<Link href={item.Url} target="_blank" onClick={(e) => onLinkClick(e, item)}>
										<DocumentTextLink20Regular />
									</Link>
								</TableCell>
								<TableCell {...columnSizing_unstable.getTableCellProps(StatusId)}>{status}</TableCell>
								{columns.map((column) => (
									<TableCell key={column.Props} {...columnSizing_unstable.getTableCellProps(column.Props)}>
										<TableCellLayout title={stringify(accessData(item, column.DisplayName))} truncate>
											{renderCell(item, column)}
										</TableCellLayout>
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				)}
			</Table>
			<section className="data-query-footer" style={{ visibility: context.DataQueryResult.Loading ? 'hidden' : 'visible' }}>
				<section className="data-query-footer-summary">
					<p>
						<span>
							<Body1Strong>Total</Body1Strong>:{context.Articles.length}
						</span>
						<span>
							<Body1Strong>Duplicated:</Body1Strong>
							{context.DuplicationMap.size}
						</span>
						<span>
							<Body1Strong>{ReviewStatus.NotReviewed}:</Body1Strong>
							{context.Articles.length -
								context.QueryData.Includes.size -
								context.QueryData.Excludes.size -
								context.DuplicationMap.size}
						</span>
					</p>
					<p>
						<span>
							<Body1Strong>{ReviewStatus.Include}:</Body1Strong>
							{context.QueryData.Includes.size}
						</span>
						<span>
							<Body1Strong>{ReviewStatus.Exclude}:</Body1Strong>
							{context.QueryData.Excludes.size}
						</span>
					</p>
				</section>
				{Pagination}
			</section>
			<PreviewAction taskId={context.MigrationId!} row={previewRow} open={previewRow !== null} onClose={onDialogClose} />
		</section>
	)
})
