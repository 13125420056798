/* eslint-disable react-hooks/exhaustive-deps */
import { Combobox, ComboboxProps, Option } from '@fluentui/react-components'
import { useEffect, useMemo, useState } from 'react'
import './EnhancedSelect.scss'
export type EnhancedOption = {
	value: string
	text?: string
	disable?: boolean
}
type Props = {
	value: string
	onOptionSelect: (value: string) => void
	options: EnhancedOption[]
	noOptionsMessage?: string
} & Omit<ComboboxProps, 'onOptionSelect'>
export const EnhancedSelect = ({ value, onOptionSelect, options, noOptionsMessage, ...props }: Props) => {
	const normalizedOption = useMemo(() => {
		return options.map((o) => ({ ...o, text: o.text ?? o.value }))
	}, [options])
	const textToValue = useMemo(() => {
		return Object.fromEntries(normalizedOption.map((o) => [o.text, o.value] as const))
	}, [normalizedOption])
	const valueToText = useMemo(() => {
		return Object.fromEntries(normalizedOption.map((o) => [o.value, o.text] as const))
	}, [normalizedOption])
	const [query, setQuery] = useState(valueToText[value] ?? '')

	const filtedOption = useMemo(() => {
		if (!query) return normalizedOption
		else {
			const text = query.toLocaleLowerCase()
			return normalizedOption.filter((o) => o.text.toLocaleLowerCase().includes(text))
		}
	}, [query, normalizedOption])
	useEffect(() => {
		setQuery(valueToText[value] ?? '')
	}, [normalizedOption, value])
	return (
		<Combobox
			clearable
			value={query}
			onChange={(e) => setQuery(e.target.value)}
			onOptionSelect={(e, d) => {
				setQuery(d.optionValue ?? '')
				onOptionSelect(textToValue[d?.optionValue ?? ''] ?? '')
			}}
			{...props}
			className="enhanced-select"
			title={query}
		>
			{filtedOption.length === 0 ? (
				<Option disabled>No Option</Option>
			) : (
				filtedOption.map((option) => (
					<Option key={option.value} value={option.text} text={option.text} disabled={option.disable}>
						{option.text}
					</Option>
				))
			)}
		</Combobox>
	)
}
