import { NavDrawer, NavDrawerBody, NavItem, OnNavItemSelectData } from '@fluentui/react-nav-preview'
import { useNavigate } from 'react-router-dom'
import { EventHandler } from '@fluentui/react-utilities'
import './fabric-icons-inline.scss'
export const SideNav = () => {
	const navigate = useNavigate()
	const onNavItemSelect: EventHandler<OnNavItemSelectData> = (ev, data) => {
		navigate(data.value)
		ev.preventDefault()
	}
	return (
		<NavDrawer type="inline" open multiple onNavItemSelect={onNavItemSelect}>
			<NavDrawerBody>
				<NavItem href="/" value="/">
					<i className="ms-Icon ms-Icon--Home" /> Home
				</NavItem>
				<NavItem href="/migration" value="/migration">
					<i className="ms-Icon ms-Icon--PageEdit" /> Migrate
				</NavItem>
			</NavDrawerBody>
		</NavDrawer>
	)
}
