import { Api } from '@/Api'
import { Combobox, Option } from '@fluentui/react-components'
import { useRequest } from 'ahooks'
import { useEffect, useState } from 'react'
type Props = {
	OnSelect(val: string): void
	Value: string
}
export const SearchSelect = (props: Props) => {
	const [query, setQuery] = useState('')
	const { data, loading } = useRequest(
		() => {
			if (query) return Api.Github.ListRepositories(query)
			else return Promise.resolve([])
		},
		{
			refreshDeps: [query],
			debounceWait: 500,
			onSuccess: (res) => {
				if (res.length === 1) {
					setQuery(res[0])
					props.OnSelect(res[0])
				}
			},
		}
	)
	useEffect(() => {
		setQuery(props.Value)
	}, [props.Value])
	const repos = data ?? []
	return (
		<Combobox
			clearable
			value={query ?? ''}
			onChange={(e) => setQuery(e.target.value)}
			onOptionSelect={(_, d) => {
				props.OnSelect(d.optionValue!)
			}}
			autoComplete="off"
		>
			{loading ? (
				<Option disabled>Loading</Option>
			) : (query ?? '').length === 0 ? (
				<Option disabled>Type your repository name</Option>
			) : repos.length === 0 ? (
				<Option disabled>No option</Option>
			) : (
				repos.map((repo) => (
					<Option key={repo} value={repo} text={repo}>
						{repo}
					</Option>
				))
			)}
		</Combobox>
	)
}
