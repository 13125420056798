import { Env } from '@env'
import { Fetch } from './Fetch'
import { DataQueryIndex } from '@components/DataQuery/Interface'

const Root = `${Env.BackendApiUrl}api/migration/SonicProxy`
const ReadIndexes = () => {
	return Fetch.Get<DataQueryIndex[]>(Root)
}
export const Sonic = {
	ReadIndexes,
} as const
