import { Env } from '@env'
import { Fetch } from './Fetch'
import { ContentCheckError, EContentCheckPoint } from './Types'
const Root = `${Env.BackendApiUrl}api/migration/ContentCheck`
const GetContentCheck = (itemId: number, checkPoints: EContentCheckPoint[]) => {
	return Fetch.Post<ContentCheckError[]>(`${Root}/SearchContentCheckError`, {
		migrationItemIds: [itemId],
		contentCheckPoints: checkPoints,
		onlyReturnLastErrors: true,
	})
}
const GetArticleContentCheck = (itemId: number, checkPoints: EContentCheckPoint[]) => {
	return Fetch.Post<ContentCheckError[]>(`${Root}/SearchContentCheckError`, {
		migrationArticleIds: [itemId],
		contentCheckPoints: checkPoints,
		onlyReturnLastErrors: true,
	})
}
export const ContentCheck = {
	GetContentCheck,
	GetArticleContentCheck,
}
