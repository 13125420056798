import { Env } from '@env'
import { Fetch } from './Fetch'
const Root = `${Env.BackendApiUrl}api/migration/Proxy`
const GetContent = (url: string) => {
	return Fetch.Get<string>({
		url: Root,
		query: {
			url,
		},
	})
}
export const Proxy = {
	GetContent,
}
