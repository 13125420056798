// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
// https://github.com/Azure-Samples/application-insights-react-demo
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { Env } from '@env'

let reactPlugin = new ReactPlugin()
const instrumentationKey = Env.Insights

let appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: instrumentationKey,
		maxBatchInterval: 0,
		disableFetchTracking: false,
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: {
				history: null,
			},
		},
	},
})

appInsights.loadAppInsights()
export const getAppInsights = () => appInsights
