import { loginRequest } from '@/Config/MsalConfig'
import { msalInstance } from '..'
import { WrapperFetch } from './OperationResult'
import { getAppInsights } from '@components/DataQuery/Utilities/TelemetryService'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { EmitToast } from '@components/GlobalToaster'

type Path =
	| string
	| {
			url: string
			query?: Record<string, string | number | boolean | (string | number)[]>
	  }

const _Fetch = async (
	path: Path,
	method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
	{ body, header }: { body?: unknown; header?: Record<string, string> }
) => {
	let url: URL
	if (typeof path === 'object') {
		url = new URL(path.url)
		if (path.query) {
			Object.entries(path.query).forEach(([k, v]) => {
				if (Array.isArray(v)) {
					v.forEach((v0) => url.searchParams.append(k, v0.toString()))
				} else url.searchParams.append(k, v.toString())
			})
		}
	} else {
		url = new URL(path)
	}
	let fetchBody: string | undefined = undefined
	let account = msalInstance.getActiveAccount()!
	const accessTokenRequest = {
		...loginRequest,
		account: account,
	}
	const tokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest)
	if (method !== 'GET') {
		if (body) {
			if (typeof body === 'string') fetchBody = body
			else fetchBody = JSON.stringify(body)
		}
	}
	try {
		const result = await fetch(url, {
			method,
			mode: 'cors',
			headers: Object.assign(
				{
					'Content-Type': 'application/json',
					Authorization: `Bearer ${tokenResponse.accessToken}`,
				},
				header
			),
			body: fetchBody,
		})
		if (result.ok) return result
		else throw result
	} catch (e) {
		let appInsight = getAppInsights()
		EmitToast('Network error.', 'error')
		appInsight.trackException({
			error: new Error(
				JSON.stringify({
					path,
					body,
					reason: e,
				})
			),
			severityLevel: SeverityLevel.Error,
		})

		return Promise.reject(e)
	}
}
const Get = <T>(path: Path, header?: Record<string, string>) => _Fetch(path, 'GET', { header }).then(WrapperFetch<T>())
const Post = <T>(path: Path, body?: unknown, header?: Record<string, string>) => _Fetch(path, 'POST', { header, body }).then(WrapperFetch<T>())
const Put = <T>(path: Path, body?: unknown, header?: Record<string, string>) => _Fetch(path, 'PUT', { header, body }).then(WrapperFetch<T>())
const Delete = <T>(path: Path, body?: unknown, header?: Record<string, string>) => _Fetch(path, 'DELETE', { header, body }).then(WrapperFetch<T>())
const Patch = <T>(path: Path, body?: unknown, header?: Record<string, string>) => _Fetch(path, 'PATCH', { header, body }).then(WrapperFetch<T>())
export const Fetch = {
	Get,
	Post,
	Put,
	Delete,
	Patch,
	Fetch: _Fetch,
} as const
