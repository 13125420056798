import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components'

export const ConvertFailDialog = ({ message }: { message?: string }) => {
	return (
		<Dialog>
			<DialogTrigger disableButtonEnhancement>
				<Button appearance="outline" className="failed">
					Failed
				</Button>
			</DialogTrigger>
			<DialogSurface style={{ minWidth: '800px' }}>
				<DialogBody>
					<DialogTitle>Error Detail:</DialogTitle>
					<DialogContent>{message}</DialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary">Close</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}
