import { Env } from '@env'
import { Fetch } from './Fetch'
import {
	MigrationId,
	MigrationItem,
	MigrationTask as TMigrationTask,
	MigrationTaskModel,
	MigrationArticle,
	MigrationItemStatusRespond,
	UpdateQueryDataReq,
	EMigrationStep,
	EStep3Status,
	Pagination,
	PaginationResponse,
	TaskStatusRespond,
} from './Types'
import { WrapperPagination } from './OperationResult'
const Root = `${Env.BackendApiUrl}api/migration/MigrationTask`
const CreateMigration = (migration: MigrationTaskModel) => {
	return Fetch.Post<MigrationId>(`${Root}/CreateMigrationTask`, migration)
}
const AddItems = (
	items: Array<
		Pick<
			MigrationItem,
			| 'migrationTaskId'
			| 'articleSourceId'
			| 'articleTitle'
			| 'articleKBID'
			| 'locale'
			| 'articleSourceSystem'
			| 'articleRevisionNumber'
			| 'articleAuthor'
			| 'articleUrl'
		>
	>
) => {
	return Fetch.Post<string>(`${Root}/BatchCreateMigrationItems`, { items })
}
const GetTask = (id: number) => {
	return Fetch.Get<TMigrationTask | null>({
		url: `${Root}/GetMigrationTask`,
		query: {
			id,
		},
	})
}
const GetTasks = () => {
	return Fetch.Get<{
		mine: TMigrationTask[]
		shared: TMigrationTask[]
	}>(`${Root}/GetAllMigrationTasks`)
}
const GetItems = (id: number, query: { articleId?: string; title?: string; status?: EStep3Status[]; onlyIncluded: boolean }, page?: Pagination) => {
	return Fetch.Get<PaginationResponse<MigrationItem>>({
		url: `${Root}/GetMigrationItems`,
		query: { taskId: id, ...query, ...page },
	}).then(WrapperPagination)
}
const GetItemIsExcluded = (migrationId: number) => {
	return Fetch.Get<MigrationArticle[]>({
		url: `${Root}/GetItemIsExcluded`,
		query: { migrationId },
	})
}
const GetItemStatusAndDates = (ids: number[], steps?: EMigrationStep[]) => {
	return Fetch.Post<MigrationItemStatusRespond[]>(`${Root}/GetItemStatusAndDates`, { items: ids, steps })
}
const RetrieveAndCheck = (ids: number[]) => {
	return Fetch.Post<string>(`${Root}/BeginArticleMigration`, ids)
}

const SaveSelectedArticle = (migrationId: number, req: UpdateQueryDataReq) => {
	return Fetch.Post<string[]>(
		{
			url: `${Root}/SaveSelectedArticle`,
			query: { migrationId },
		},
		req
	)
}
const FinishSelecteArticle = (migrationId: number) => {
	return Fetch.Post({ url: `${Root}/FinishSelecteArticle`, query: { migrationId } })
}
const ModifyMigration = (migrationId: number, migration: MigrationTaskModel) => {
	return Fetch.Patch<MigrationId>(
		{
			url: `${Root}/ModifyMigration`,
			query: { migrationId },
		},
		migration
	)
}
const CheckDuplications = (migrationId: number, includes: string[]) => {
	return Fetch.Post<{ contentId: string; migrationName: string }[]>(
		{
			url: `${Root}/CheckDuplications`,
			query: { migrationId },
		},
		includes
	)
}
const GetTaskStatus = (ids: number[]) => {
	return Fetch.Get<TaskStatusRespond>({
		url: `${Root}/GetTaskStatus`,
		query: { ids },
	})
}
const IsItemConverted = (taskId: number, articleSourceId: string) => {
	return Fetch.Get<boolean>({
		url: `${Root}/IsItemConverted`,
		query: {
			taskId,
			articleSourceId,
		},
	})
}
export const MigrationTask = {
	CreateMigration,
	ModifyMigration,
	AddItems,
	GetTask,
	GetTasks,
	GetItems,
	GetItemIsExcluded,
	GetItemStatusAndDates,
	RetrieveAndCheck,
	SaveSelectedArticle,
	FinishSelecteArticle,
	CheckDuplications,
	GetTaskStatus,
	IsItemConverted,
} as const
