import { Api } from '@/Api'
import { ReviewStatus } from '@/Api/Types'
import { ArticleData } from '@components/DataQuery/DataQueryContext/ArticleData'
import {
	Dialog,
	Button,
	DialogActions,
	DialogBody,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	DialogContent,
	Link,
	Spinner,
} from '@fluentui/react-components'
import { LinkRegular } from '@fluentui/react-icons'
import { useRequest, useToggle } from 'ahooks'
import { useEffect, useRef, useState } from 'react'
export enum ReviewResult {
	Include,
	Exclude,
	Reset,
	NoResult,
}
type Props = {
	open: boolean
	onClose: (result: ReviewResult) => void
	taskId?: number
	row?: ArticleData | null
}
export const PreviewAction = (props: Props) => {
	const ref = useRef<HTMLIFrameElement>(null)
	const url = props.row?.Url ?? ''
	const [loading, { set }] = useToggle()
	const [doc, setDoc] = useState<Document | null>(null)
	useEffect(() => {
		if (props.open && url) {
			set(true)
			Api.Proxy.GetContent(url).then((res) => {
				const parser = new DOMParser()
				const parsedDocument = parser.parseFromString(res, 'text/html')
				parsedDocument.body.querySelectorAll('script').forEach((n) => n.remove())
				setDoc(parsedDocument)

				set(false)
			})
		}
	}, [props.open, url])
	useEffect(() => {
		if (doc && ref.current) {
			const r = ref.current
			r.contentDocument!.head.innerHTML = `<base href="https://internal.evergreen.microsoft.com/" target="_blank"/>${doc.head.innerHTML}`
			r.contentDocument!.body.append(doc.body)
		}
	}, [doc, ref])
	const { data: isConverted } = useRequest(
		() => {
			if (props.taskId && props.row?.['Metadata.ContentID'])
				return Api.MigrationTask.IsItemConverted(props.taskId, props.row?.['Metadata.ContentID'])
			return Promise.resolve(true)
		},
		{
			refreshDeps: [props.taskId, props.row],
		}
	)
	return (
		<Dialog open={props.open}>
			<DialogSurface className="preview-dialog ">
				<DialogBody>
					<DialogTitle>
						Preview
						<Link href={url} target="_blank" style={{ verticalAlign: 'middle' }}>
							<LinkRegular />
						</Link>
					</DialogTitle>
					<DialogContent>
						{loading ? <Spinner /> : url && <iframe ref={ref} className="article-content" title="Preview"></iframe>}
					</DialogContent>
					<DialogActions>
						<Button appearance="primary" onClick={() => props.onClose(ReviewResult.Include)} disabled={isConverted}>
							{ReviewStatus.Include}
						</Button>
						<Button appearance="primary" onClick={() => props.onClose(ReviewResult.Exclude)} disabled={isConverted}>
							{ReviewStatus.Exclude}
						</Button>
						<Button appearance="secondary" onClick={() => props.onClose(ReviewResult.Reset)} disabled={isConverted}>
							Reset review status
						</Button>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary" onClick={() => props.onClose(ReviewResult.NoResult)}>
								Close
							</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}
