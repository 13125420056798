import './DataQueryBuilder.scss'
import { getMaxDepth } from '@components/DataQuery/Components/DataQueryBuilder/QueryBuilderUtils'
import { QueryRows } from './QueryRows'
import { Button, Table, TableBody, TableHeader, TableHeaderCell, TableRow, Tooltip } from '@fluentui/react-components'
import { Add20Regular, BranchForkRegular } from '@fluentui/react-icons'
import { observer } from 'mobx-react-lite'
import { useQueryContext } from '@components/DataQuery/DataQueryContext'

export const DataQueryBuilder = observer(() => {
	const context = useQueryContext()
	const numOfGroupCols = Math.max(0, getMaxDepth(context.Expression) - 1)
	return (
		<div className="container query-builder open">
			<Table>
				<TableHeader>
					<TableRow>
						<TableHeaderCell className="add-remove" />
						<TableHeaderCell className="group">
							<Tooltip content={context.GroupingHoverText} relationship="label">
								<Button
									icon={<BranchForkRegular />}
									appearance="transparent"
									title="Group"
									aria-label="Group"
									className="groupRowIcon"
									onClick={context.GroupRows}
								/>
							</Tooltip>
						</TableHeaderCell>
						{new Array(numOfGroupCols).fill(1).map((v, i) => (
							<TableHeaderCell key={i} className="group-col" />
						))}
						<TableHeaderCell className="and-or">And/Or</TableHeaderCell>
						<TableHeaderCell className="field">Field</TableHeaderCell>
						<TableHeaderCell className="operator">Operator</TableHeaderCell>
						<TableHeaderCell className="value">Value</TableHeaderCell>
					</TableRow>
				</TableHeader>
				<TableBody>
					<QueryRows />
				</TableBody>
			</Table>
			<Button appearance="transparent" icon={<Add20Regular />} onClick={context.AppendNewExpression}>
				Add new clause
			</Button>
		</div>
	)
})
