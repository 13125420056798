import { makeAutoObservable, observable, runInAction } from 'mobx'
import {
	insertNewExpression,
	appendNewExpression,
	groupSelectedExpressions,
	ungroupExpressions,
	setExpression,
	getGroupingHoverText,
	initExprTree,
} from '../Components/DataQueryBuilder/ExpressionTreeUtils'
import {
	defaultFieldOperators,
	defaultFieldValues,
	MetadataQueryOperator,
	isSetOperator,
	isNotSetOperator,
	groupHoverText_notEnough,
} from '../Components/DataQueryBuilder/QueryBuilderConstants'
import {
	fixParent,
	generateOrderby,
	generateSearchStrings,
	getExpression,
	QueryExpression,
	removeExpression,
	removeInvalidExpressions,
} from '../Components/DataQueryBuilder/QueryBuilderUtils'
import { createContext, useContext } from 'react'
import { DataQueryIndex, DataField, MetaDataLayout } from '../Interface'
import { exportCurationFilterData } from '../Utilities/ExoportDataUtils'
import { Api } from '@/Api'
import { getDefaultDateTime } from '../Utilities/CommonFunction'
import { buildConditionJson } from '../Utilities/QueryBuilderExtension'
import { DataQueryResult, DynamicColumn } from './DataQueryResult'
import { ArticleData } from './ArticleData'
import { DefaultColumnSorting } from '@/Special/DefaultColums'
import { NewMigrationContext } from '@components/NewMigration/Context'
import { parse, stringify } from 'flatted'
import { ArticleSourceSystem, EReviewStatus, ReviewStatus } from '@/Api/Types'
import { ExportToExcel } from './ExportToExcel'
import { RootConfig } from '@/Config/Const'
import { StepContext } from '@components/NewMigration/Context/Types'
import { Env } from '@env'
type QueryData = {
	Includes: Set<string>
	Excludes: Set<string>
}
export interface IExpressions {
	id: number
	group: boolean
	andOr: string | null
	field: string | null
	fieldType: string | null
	operator: string | null
	value: string | null
	children?: IExpressions[]
}

const api = Api.MsacctAPI
export type ColumnSorting = {
	Props: DynamicColumn['Props']
	Sorting: 'desc' | 'asc'
}

let cachedIndex: DataQueryIndex[] | undefined = undefined
let cachedFieldMap: Map<string, DataField[]> = new Map()
export class DataQueryContext implements StepContext {
	public constructor(private rootContext: NewMigrationContext) {
		this.groupingHoverText = groupHoverText_notEnough
		this.queryData = {
			Includes: new Set(),
			Excludes: new Set(),
		}
		this.initQueryData = {
			Includes: new Set(),
			Excludes: new Set(),
		}
		this.expression = initExprTree()
		this.indexList = []
		this.index = {
			name: '',
			IndexPrefix: '',
			metadataSchema: Env.SonicIndex.Index,
			searchableBranches: [],
			searchService: '',
		}
		this.searchResult = new DataQueryResult()
		this.layout = { showColumnOptionsDialog: false, showConfigrationDialog: false }
		this.metadataFields = new Map()
		this.columnSorting = DefaultColumnSorting
		this.reviewedArticle = new Map()
		this.duplicationMap = new Map()
		this.dirtyArticle = new Map()
		this.currentStep = 'AfterSave'
		makeAutoObservable(this, { expression: observable.ref })
	}
	public get Articles() {
		const set = new Set<string>([...this.DuplicationMap.keys()])
		let result = this.DataQueryResult.Articles.filter((a) => set.has(a['Metadata.ContentID']))
		this.InitQueryData.Includes.forEach((i) => {
			set.add(i)
			const a = this.ReviewedArticle.get(i)
			if (a) result.push(a)
		})
		this.InitQueryData.Excludes.forEach((i) => {
			set.add(i)
			const a = this.ReviewedArticle.get(i)
			if (a) result.push(a)
		})
		result.push(...this.searchResult.Articles.filter((a) => !set.has(a['Metadata.ContentID'])))
		return result
	}

	get CanMoveTo(): boolean {
		return !!this.rootContext.MigrationId
	}
	public InitState() {
		this.LoadDataQueryIndexes().then(() => {
			if (this.IndexList?.length) {
				this.Index = this.IndexList[0]
				const loadedSchemas: string[] = []
				this.IndexList.forEach((r) => {
					let metadataSchema = r.metadataSchema
					if (metadataSchema && !loadedSchemas.includes(metadataSchema)) {
						this.LoadMetadataFields(metadataSchema)
						loadedSchemas.push(metadataSchema)
					}
				})
			}
		})
	}
	public get MigrationId() {
		return this.rootContext.MigrationId
	}
	public SetDefaultExpression() {
		this.Expression = initExprTree()
		const expoLcale = new QueryExpression()
		expoLcale.field = 'Locale'
		expoLcale.fieldType = 'string'
		expoLcale.operator = '='
		expoLcale.value = 'en-us'
		this.AppendExpression(1, expoLcale)

		let groupChild1 = new QueryExpression()
		groupChild1.group = true
		groupChild1.andOr = 'And'
		groupChild1.field = 'LastPublishDate'
		groupChild1.fieldType = 'datetime'
		groupChild1.operator = 'Is after'
		groupChild1.value = getDefaultDateTime()
		this.AppendExpression(2, groupChild1)

		let groupChild2 = new QueryExpression()
		groupChild2.group = true
		groupChild2.andOr = 'Or'
		groupChild2.field = 'ArticleCreatedDate'
		groupChild2.fieldType = 'datetime'
		groupChild2.operator = 'Is after'
		groupChild2.value = getDefaultDateTime()
		this.AppendExpression(3, groupChild2)
		this.OnUpdateGroupingCheckbox(2)
		this.OnUpdateGroupingCheckbox(3)
		this.GroupRows()
		this.RemoveExpression(0)
	}
	public get GroupingHoverText(): string {
		return this.groupingHoverText
	}
	public set GroupingHoverText(v: string) {
		this.groupingHoverText = v
	}
	public expression: QueryExpression
	public get Expression() {
		return this.expression
	}
	public set Expression(v: QueryExpression) {
		this.expression = v
		this.CurrentStep = 'AfterRun'
	}
	/**
	 * Adds a new empty expression before the specified index
	 * @param rowIndex Position in array to add new expression
	 */
	public AddExpression = (rowIndex: number) => {
		const newRoot = this.CloneExpression()
		insertNewExpression(newRoot, rowIndex)
		this.Expression = newRoot
	}
	public RemoveInvalidExpressions = () => {
		const newRoot = this.CloneExpression()
		removeInvalidExpressions(newRoot)
		this.Expression = newRoot
	}
	public AppendNewExpression = () => {
		const newRoot = this.CloneExpression()
		appendNewExpression(newRoot)
		this.Expression = newRoot
	}
	/**
	 * @description Removes an expression from tree
	 * @param rowIndex Position of expression to remove
	 */
	public RemoveExpression(rowIndex: number) {
		const newRoot = this.CloneExpression()
		removeExpression(newRoot, rowIndex)
		this.Expression = newRoot
	}

	/**
	 * @description Creates a new expression group consisting of selected rows,
	 * and inserts group into tree at position of closest common ancestor
	 */
	public GroupRows = () => {
		const newRoot = this.CloneExpression()
		groupSelectedExpressions(newRoot)
		this.Expression = newRoot
	}
	/**
	 * @description Ungroups target expresstion group
	 * @param groupID ID of target expression group
	 */
	public OnUngroupExpressions(groupID: number) {
		const newRoot = this.CloneExpression()
		ungroupExpressions(newRoot, groupID)
		this.Expression = newRoot
	}
	/**
	 * @description Overwrites the expression at target rowIndex
	 * @param rowIndex Position in QueryRows of target expression
	 * @param newExpression New values for target expression to use
	 */
	public SetExpression(rowIndex: number, newExpression: QueryExpression) {
		const newRoot = this.CloneExpression()
		setExpression(newRoot, rowIndex, newExpression)
		this.Expression = newRoot
	}
	/**
	 * @description Sets grouping operator for target expression in array
	 * @param rowIndex Position in expression array to update
	 * @param key Selected grouping operator (And/Or)
	 */
	public OnUpdateAndOr(rowIndex: number, key: string) {
		const root = this.CloneExpression()
		const expr = getExpression(root, rowIndex)
		expr.andOr = key
		this.SetExpression(rowIndex, expr)
	}
	/**
	 * @description Sets field for target expression in array
	 * @rowIndex Position in expression array to update
	 * @option Selected field
	 */
	public OnUpdateField(rowIndex: number, option: { FieldName: string; FieldType: string }) {
		const root = this.CloneExpression()
		const expr = getExpression(root, rowIndex)
		expr.field = option.FieldName
		if (expr.fieldType !== option.FieldType) {
			expr.fieldType = option.FieldType
			// set default operator and value
			if (option.FieldType in defaultFieldOperators) expr.operator = (defaultFieldOperators as any)[option.FieldType]
			if (option.FieldType in defaultFieldValues) expr.value = (defaultFieldValues as any)[option.FieldType]
		}
		this.SetExpression(rowIndex, expr)
	}
	/**
	 * @description Sets operator for target expression in array
	 * @param Position in expression array to update
	 * @param operator Selected operator
	 */
	public OnUpdateOperator(rowIndex: number, operator: MetadataQueryOperator) {
		const root = this.CloneExpression()
		const expr = getExpression(root, rowIndex)
		expr.operator = operator
		if (operator === isSetOperator || operator === isNotSetOperator) {
			expr.value = ''
		} else {
			if (expr.fieldType in defaultFieldValues) expr.value = (defaultFieldValues as any)[expr.fieldType]
		}
		this.SetExpression(rowIndex, expr)
	}
	/**
	 * @description Sets value for target expression in array
	 * @param rowIndex Position in expression array to update
	 * @param value New value
	 */
	public OnUpdateValue(rowIndex: number, value: string) {
		const root = this.CloneExpression()
		const expr = getExpression(root, rowIndex)
		expr.field === 'guid' ? (expr.value = value.toLocaleLowerCase()) : (expr.value = value)
		this.SetExpression(rowIndex, expr)
	}
	/**
	 * @description Clears query expressions
	 */
	public ClearQuery() {
		this.Expression = initExprTree()
	}
	/**
	 * @description Toggles grouping checkbox for target expression in array
	 * @param rowIndex Position in expression array to update
	 */
	public OnUpdateGroupingCheckbox(rowIndex: number) {
		const root = this.CloneExpression()
		const expr = getExpression(root, rowIndex)
		expr.group ? (expr.group = false) : (expr.group = true)
		this.SetExpression(rowIndex, expr)
		this.GroupingHoverText = getGroupingHoverText(this.Expression)
	}
	public AppendExpression(rowIndex: number, queryExpressions: QueryExpression) {
		this.AppendNewExpression()
		this.OnUpdateAndOr(rowIndex, queryExpressions.andOr)

		let myFieldOptions = {
			FieldType: queryExpressions.fieldType,
			FieldName: queryExpressions.field,
		}
		this.OnUpdateField(rowIndex, myFieldOptions)
		this.OnUpdateOperator(rowIndex, queryExpressions.operator)
		this.OnUpdateValue(rowIndex, queryExpressions.value)
	}
	public async LoadDataQueryIndexes() {
		if (cachedIndex === undefined) {
			const i = await api.powerQueryService.getQueryDataIndex()
			cachedIndex = i
			runInAction(() => {
				this.indexList = i
			})
		} else {
			this.indexList = cachedIndex
		}
	}
	public async LoadPreSelectArticles(ids: string[]) {
		const keys = ids
		if (keys.length === 0) return
		this.DataQueryResult.Loading = true
		const articles = await SonicAPIWrapper.SearchArticles(keys, this.Index.metadataSchema)
		runInAction(() => {
			articles.forEach((article) => {
				this.ReviewedArticle.set(article['Metadata.ContentID'], article)
			})
		})
		this.DataQueryResult.Loading = false
	}
	public async LoadMetadataFields(metadataSchemaId: string) {
		if (this.metadataFields.has(metadataSchemaId)) return
		if (cachedFieldMap.has(metadataSchemaId)) {
			this.metadataFields.set(metadataSchemaId, cachedFieldMap.get(metadataSchemaId)!)
			return
		}
		const fields = await api.powerQueryService.getDataFields(metadataSchemaId)
		cachedFieldMap.set(metadataSchemaId, fields)
		runInAction(() => {
			this.metadataFields.set(metadataSchemaId, fields)
		})
	}
	private MoveDirtyArticleToReviewed() {
		this.DirtyArticle.forEach((v, k) => {
			this.ReviewedArticle.set(k, v)
		})
		this.DirtyArticle.clear()
	}
	public async FetchData() {
		this.MoveDirtyArticleToReviewed()
		const allFields = this.Fields
		const { filterString } = generateSearchStrings(this.Expression, allFields)
		const filterJson = JSON.stringify(buildConditionJson(this.Expression.children))
		let searchDataIndex = this.DataQueryResult.PageIndex * this.DataQueryResult.PageSize
		this.DataQueryResult.Loading = true
		const orderBy = generateOrderby(this.ColumnSorting, allFields)
		const content = await SonicAPIWrapper.SearchContent(
			'*',
			this.Index.metadataSchema,
			filterString,
			filterJson,
			'*',
			this.DataQueryResult.PageSize,
			searchDataIndex,
			orderBy
		)
		runInAction(() => {
			this.DataQueryResult.Articles = content.Topics
			this.DataQueryResult.Total = content.Count
			this.DataQueryResult.HasMore = content.Topics.length === this.DataQueryResult.PageSize
			this.DataQueryResult.Loading = false
		})
		return content
	}

	public ClearSearchResult() {
		this.searchResult.Clear()
		this.DuplicationMap.clear()
	}
	//public
	/**
	 * TODO : Remove from store
	 * @param searchQuery
	 * @param searchIndex
	 * @param indexPrefix
	 * @param filterStr
	 * @param selectStr
	 * @param fileName
	 */
	public async ExportSearchResult(
		searchQuery: string,
		searchIndex: string,
		indexPrefix: string,
		filterStr: string,
		selectStr: string,
		fileName: string
	) {
		const searchResults = await api.powerQueryService.downloadSearchResult(searchQuery, searchIndex, indexPrefix, filterStr, selectStr)

		exportCurationFilterData(searchResults, fileName)
	}
	public SetColumnOptionsDialogVisible(val: boolean) {
		this.layout.showColumnOptionsDialog = val
	}
	public SetConfigrationDialogVisible(val: boolean) {
		this.layout.showConfigrationDialog = val
	}
	public SetQueryPage(page: number) {
		this.DataQueryResult.PageIndex = page
		this.FetchData()
	}
	public UnselectedRow(ids: string[]) {
		ids.forEach((v) => {
			this.QueryData.Includes.delete(v)
		})
	}

	public get IndexList() {
		return this.indexList
	}
	public get Index() {
		return this.index
	}
	public set Index(val: DataQueryIndex) {
		this.index = val
	}
	public get Fields() {
		return this.metadataFields.get(this.index.metadataSchema) ?? []
	}
	public get MetadataFields() {
		return this.metadataFields
	}
	public get DataQueryResult() {
		return this.searchResult
	}
	public get ShowColumnOptionsDialog() {
		return this.layout.showColumnOptionsDialog
	}
	public get ShowConfigrationDialog() {
		return this.layout.showConfigrationDialog
	}
	public get ColumnSorting() {
		return this.columnSorting
	}
	public set ColumnSorting(val: ColumnSorting[]) {
		this.columnSorting = val
	}
	private indexList: DataQueryIndex[]
	private index: DataQueryIndex
	private metadataFields: Map<string, DataField[]>
	private searchResult: DataQueryResult
	private layout: MetaDataLayout
	private groupingHoverText: string
	private columnSorting: ColumnSorting[]
	private CloneExpression() {
		return { ...this.expression }
	}

	private initQueryData: QueryData
	public get InitQueryData(): QueryData {
		return this.initQueryData
	}
	public set InitQueryData(v: QueryData) {
		this.initQueryData = v
	}

	private queryData: QueryData
	public get QueryData() {
		return this.queryData
	}
	public GetQueryData() {
		return {
			Expression: stringify(this.Expression),
			Excludes: [...this.QueryData.Excludes],
			Includes: [...this.QueryData.Includes],
		}
	}
	public LoadQueryData(filter: any) {
		const exp = parse(filter['Expression']) as QueryExpression
		fixParent(exp)
		this.Expression = exp
		this.CurrentStep = 'AfterSave'
	}
	public Save() {
		const map = new Map(this.ReviewedArticle)
		this.DirtyArticle.forEach((v, k) => {
			map.set(k, v)
		})
		return Api.MigrationTask.SaveSelectedArticle(this.MigrationId!, {
			QueryData: this.GetQueryData(),
			items: [...map.values()].map((row) => ({
				migrationTaskId: this.MigrationId!,
				articleSourceId: row['Metadata.ContentID'],
				articleTitle: row.Title,
				articleKBID: row['Metadata.KBID'],
				locale: row.Locale,
				articleSourceSystem: ArticleSourceSystem.Evergreen,
				articleRevisionNumber: Number(row['Metadata.RevisionNumber']) ?? 0,
				articleAuthor: row['Properties.Contacts.WriterName'] ?? '',
				articleUrl: row.Url,
			})),
		}).then((res) => {
			this.CurrentStep = 'AfterSave'
			return res
		})
	}
	public GetRowReviewStatus(id: string): EReviewStatus | string {
		if (this.QueryData.Excludes.has(id)) return ReviewStatus.Exclude
		if (this.QueryData.Includes.has(id)) return ReviewStatus.Include
		if (this.DuplicationMap.has(id)) return `duplicated with migration ${this.DuplicationMap.get(id)}`
		return ReviewStatus.NotReviewed
	}
	private dirtyArticle: Map<string, ArticleData>
	public get DirtyArticle() {
		return this.dirtyArticle
	}
	private reviewedArticle: Map<string, ArticleData>
	public get ReviewedArticle() {
		return this.reviewedArticle
	}
	public async GetExportArticles() {
		const allFields = this.Fields
		const { filterString } = generateSearchStrings(this.Expression, allFields)
		const filterJson = JSON.stringify(buildConditionJson(this.Expression.children))
		const orderBy = generateOrderby(this.ColumnSorting, allFields)
		const content = await SonicAPIWrapper.SearchContent(
			'*',
			this.Index.metadataSchema,
			filterString,
			filterJson,
			'*',
			RootConfig.SonicMaxQueryItem,
			0,
			orderBy
		)
		return content
	}
	public async Export(articles: ArticleData[]) {
		const { filterString } = generateSearchStrings(this.Expression, this.Fields)
		ExportToExcel(articles, this.DataQueryResult.Columns, filterString)
	}
	public get CanNext() {
		return (
			this.CurrentStep === 'AfterSave' &&
			this.QueryData.Includes.size !== 0 &&
			this.DataQueryResult.Total < RootConfig.SonicMaxQueryItem &&
			!this.DataQueryResult.Loading
		)
	}

	private duplicationMap: Map<string, string>
	public get DuplicationMap(): Map<string, string> {
		return this.duplicationMap
	}
	public set DuplicationMap(v: Map<string, string>) {
		this.duplicationMap = v
	}

	private currentStep: 'AfterRun' | 'AfterCheckDuplication' | 'AfterSave'
	public get CurrentStep() {
		return this.currentStep
	}
	public set CurrentStep(v: typeof this.currentStep) {
		this.currentStep = v
	}
	public get NotReviewed() {
		return this.DataQueryResult.Total - this.QueryData.Includes.size - this.QueryData.Excludes.size - this.DuplicationMap.size
	}
}
export const QueryContext = createContext(new DataQueryContext(null!))
export const useQueryContext = () => useContext(QueryContext)
export const SonicAPIWrapper = {
	SearchArticles: (ids: string[], searchIndex: string) => api.powerQueryService.searchArticles(ids, searchIndex),
	SearchContent: (
		searchQuery: string,
		searchIndex: string,
		filterStr: string,
		filterJson: string,
		selectStr: string,
		top?: number,
		skip?: number,
		orderByField?: string
	) => api.powerQueryService.searchContent(searchQuery, searchIndex, filterStr, filterJson, selectStr, top, skip, orderByField),
	ExportSearchResult: async (
		searchQuery: string,
		searchIndex: string,
		indexPrefix: string,
		filterStr: string,
		selectStr: string,
		fileName: string
	) => {
		const searchResults = await api.powerQueryService.downloadSearchResult(searchQuery, searchIndex, indexPrefix, filterStr, selectStr)

		exportCurationFilterData(searchResults, fileName)
	},
}
