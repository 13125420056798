import { ArticleData } from './ArticleData'
import { DynamicColumn } from './DataQueryResult'
import { Workbook, Worksheet } from 'exceljs'
import { saveAs } from 'file-saver'
export const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5

function autoSize(sheet: Worksheet, fromRow: number) {
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')
	if (!ctx) {
		return
	}

	const maxColumnLengths: Array<number> = []
	sheet.eachRow((row, rowNum) => {
		if (rowNum < fromRow) {
			return
		}

		row.eachCell((cell, num) => {
			if (typeof cell.value === 'string') {
				if (maxColumnLengths[num] === undefined) {
					maxColumnLengths[num] = 0
				}

				const fontSize = cell.font && cell.font.size ? cell.font.size : 11
				ctx.font = `${fontSize}pt Arial`
				const metrics = ctx.measureText(cell.value)
				const cellWidth = metrics.width

				maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth)
			}
		})
	})

	for (let i = 1; i <= sheet.columnCount; i++) {
		const col = sheet.getColumn(i)
		const width = maxColumnLengths[i]
		if (width) {
			col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 1
		}
	}
}
export const ExportToExcel = (article: ArticleData[], columns: DynamicColumn[], filter: string) => {
	const workbook = new Workbook()
	const sheet = workbook.addWorksheet('Sheet1')
	sheet.columns = columns.map((c) => ({
		header: c.Text,
		key: c.DisplayName.replaceAll(' > ', '.'),
	}))
	sheet.addRows(article)
	const insertedRow = sheet.insertRow(1, {})
	insertedRow.getCell(1).value = 'Filter:'
	insertedRow.getCell(2).value = filter
	sheet.mergeCells(1, 2, 1, columns.length)
	autoSize(sheet, 2)
	workbook.xlsx
		.writeBuffer()
		.then(
			(buffer) =>
				new Blob([buffer], {
					type: 'application/octet-stream',
				})
		)
		.then((b) => {
			saveAs(b, 'select.xlsx')
		})
}
