import { Button } from '@fluentui/react-components'
import { BranchRegular } from '@fluentui/react-icons'

interface GroupColumnsProps {
	groupID: number
	whiteSpace: boolean
	topBorder: boolean
	bottomBorder: boolean
	colSpan: number
	category: number
	onUngroupExpressions: (groupID: number) => void
}

export const GroupColumn = (props: GroupColumnsProps) => {
	let classStr = ''
	if (!props.whiteSpace) {
		classStr += `grp-cat-${props.category}`
		if (props.topBorder) {
			classStr += ` grp-pos-start`
		} else if (props.bottomBorder) {
			classStr += ` grp-pos-end`
		}
	}

	return (
		<td className={classStr} colSpan={props.colSpan}>
			{props.topBorder ? (
				<Button
					icon={<BranchRegular />}
					appearance="transparent"
					title="Ungroup clauses"
					className="ungroupIcon"
					onClick={() => props.onUngroupExpressions(props.groupID)}
				/>
			) : null}
		</td>
	)
}
