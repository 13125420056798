import { Api } from '@/Api'
import { EContentCheckPoint, ContentCheckError, MigrationArticle, ContentCheckPoint } from '@/Api/Types'
import { FormatDate } from '@/Utilities'
import {
	Body1,
	Body1Strong,
	Button,
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from '@fluentui/react-components'
import { useState, useEffect } from 'react'
type Props = {
	item: MigrationArticle
}
const checkpoints = Object.entries({
	[ContentCheckPoint.LMCMediaCheck]: 'Media Check',
}) as [EContentCheckPoint, string][]
const cp = checkpoints.map(([k]) => k)
export const AfterConvertCheckFailDialog = ({ item }: Props) => {
	const [errors, setErrors] = useState({} as { [key in EContentCheckPoint]?: ContentCheckError })
	useEffect(() => {
		Api.ContentCheck.GetArticleContentCheck(item.id, cp).then((r) => {
			setErrors(Object.fromEntries(r.map((i) => [i.contentCheckPoint, i])))
		})
	}, [item.id])
	return (
		<Dialog>
			<DialogTrigger disableButtonEnhancement>
				<Button appearance="outline" className="failed">
					Warning
				</Button>
			</DialogTrigger>
			<DialogSurface style={{ minWidth: '800px' }}>
				<DialogBody>
					<DialogTitle>Check Error Detail:</DialogTitle>
					<DialogContent>
						<section className="grid-2">
							<section>
								<Body1Strong>Content Id:</Body1Strong>
								<Body1>{item.articleSourceId}</Body1>
							</section>
							<section>
								<Body1Strong>Author:</Body1Strong>
								<Body1>{item.sourceAuthor}</Body1>
							</section>
						</section>
						<Table noNativeElements>
							<TableHeader>
								<TableRow>
									<TableHeaderCell />
									<TableHeaderCell>Check Date</TableHeaderCell>
									<TableHeaderCell>Result</TableHeaderCell>
								</TableRow>
							</TableHeader>
							<TableBody>
								{checkpoints.map(([checkpoint, display]) => {
									const error = errors[checkpoint]
									return (
										<TableRow key={checkpoint}>
											<TableCell>{display}</TableCell>
											<TableCell>{FormatDate(error?.createdTime ?? item.lastModifiedTime)}</TableCell>
											<TableCell>{error?.errorContent ?? 'Pass'}</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</DialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance="secondary">Close</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
}
