import { Env } from '@env'
import { Fetch } from './Fetch'
export const defaultConfig = {
	Query: {
		MaxSize: 100,
	},
	'MS.Topic': [] as string[],
	'MS.Service': [] as string[],
}
export type AppConfig = typeof defaultConfig
export type ConfigKey = keyof AppConfig
export type ConfigResult<T extends ConfigKey[]> = {
	[key in T[number]]: AppConfig[key]
}

const Root = `${Env.BackendApiUrl}api/migration/CommonConfig`
async function GetConfig<T extends ConfigKey[]>(...args: T) {
	const record = await Fetch.Get<Record<string, string>>({
		url: Root,
		query: {
			keys: args,
		},
	})
	const result = Object.fromEntries(Object.entries(record).map(([k, v]) => [k, JSON.parse(v)]))
	args.forEach((arg) => {
		if (!result[arg]) result[arg] = defaultConfig[arg]
	})
	return result as ConfigResult<T>
}
export const Config = {
	GetConfig,
} as const
