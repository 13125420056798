import { useMemo } from 'react'
import './QueryRows.css'
import { GroupColumn } from './GroupColumn'
import { ValueField } from './ValueField'
import { Checkbox, TableRow, TableCell } from '@fluentui/react-components'
// import constants
import {
	MetadataQueryOperator,
	numericalOperators,
	stringOperators,
	dateOperators,
	fullTextOperators,
	sysChangedDateOperators,
	booleanOperators,
	enumOperators,
	isSetOperator,
	isNotSetOperator,
	QueryPropsType,
} from './QueryBuilderConstants'

// import common function
import { QueryExpression, getMaxDepth, getQueryRows, getGroupInfo } from './QueryBuilderUtils'
import { observer } from 'mobx-react-lite'
import { Add20Filled, Dismiss20Filled } from '@fluentui/react-icons'
import { EnhancedOption, EnhancedSelect } from '@components/EnhancedSelect'
import { useQueryContext } from '@components/DataQuery/DataQueryContext'

export const QueryRows = observer(() => {
	const context = useQueryContext()
	const fields = context.Fields

	const metadataFieldOptions = useMemo(() => {
		return fields.map((f) => ({ value: f.FieldName, text: f.DisplayName, option: f }))
	}, [fields])
	const getGroupColumns = (expr: QueryExpression, maxDepth: number) => {
		const groupCols = getGroupInfo(expr)

		// count colsUsed
		let colsUsed = 0
		for (const group of groupCols) {
			colsUsed += group.colSpan
		}

		// pad cols with whitespace if needed
		const numWhitespace = maxDepth - 1 - colsUsed
		if (numWhitespace > 0) {
			groupCols.unshift({
				colSpan: numWhitespace,
				whiteSpace: true, // ignore coloring/borders CSS
			} as any)
		}

		return groupCols
	}

	/**
	 * @param {string} fieldName selected value key to match enum values
	 */
	const getFieldValueOptions = (fieldName: string) => {
		// check if loaded
		if (!fields) return []

		// filter target field from schema on fieldName
		const targetField = fields.filter((field) => field.FieldName === fieldName)[0]

		// return valueOptions of targetField
		return targetField ? targetField.EnumValues : []
	}

	const queryRows = getQueryRows(context.Expression)
	const maxDepth = getMaxDepth(context.Expression)

	return (
		<>
			{queryRows.map((row, index: number) => {
				const fieldInfo = fields.find((f) => f.FieldName === row.field)
				const isMultiValue = fieldInfo && fieldInfo.MultiValue
				let validOperators: EnhancedOption[]
				switch (row.fieldType) {
					case QueryPropsType.Text:
						if (isMultiValue) {
							// list type (|| delim values)
							validOperators = enumOperators
						} else {
							// string type
							validOperators = stringOperators
						}
						break
					case QueryPropsType.Date:
						validOperators = dateOperators
						break
					case QueryPropsType.Numerical:
						validOperators = numericalOperators
						break
					case QueryPropsType.Boolean:
						validOperators = booleanOperators
						break
					case QueryPropsType.Fulltext:
						validOperators = fullTextOperators
						break
					case QueryPropsType.SysChangedDate:
						validOperators = sysChangedDateOperators
						break
					/**
					 * ToDo: TASK 3995341 - Add support for ENUM types
					 */
					default:
						validOperators = stringOperators
				}

				return (
					<TableRow className="query-row" key={index}>
						<TableCell>
							<section className="add-remove-group">
								<span className="add">
									<Add20Filled onClick={() => context.AddExpression(index)} />
								</span>
								<span className="remove">
									<Dismiss20Filled onClick={() => context.RemoveExpression(index)} />
								</span>
							</section>
						</TableCell>
						<TableCell>
							<Checkbox
								className="group-checkbox"
								checked={row.group}
								onChange={() => {
									context.OnUpdateGroupingCheckbox(index)
								}}
							/>
						</TableCell>
						{/* Grouping depth */}
						{getGroupColumns(row, maxDepth).map((groupCol, ind) => (
							<GroupColumn
								key={ind}
								groupID={groupCol.node ? groupCol.node.id : -1}
								whiteSpace={groupCol.whiteSpace}
								topBorder={groupCol.position === 'start'}
								bottomBorder={groupCol.position === 'end'}
								colSpan={groupCol.colSpan}
								category={groupCol.category}
								onUngroupExpressions={() => context.OnUngroupExpressions(groupCol.node ? groupCol.node.id : -1)}
							/>
						))}
						<TableCell>
							{index === 0 ? null : (
								<EnhancedSelect
									value={row.andOr ?? ''}
									options={[{ value: 'And' }, { value: 'Or' }]}
									onOptionSelect={(e) => context.OnUpdateAndOr(index, e)}
								/>
							)}
						</TableCell>
						<TableCell className="field">
							<EnhancedSelect
								options={metadataFieldOptions}
								value={row.field}
								onOptionSelect={(e) => context.OnUpdateField(index, metadataFieldOptions.find((o) => o.value === e)!.option)}
							/>
						</TableCell>
						<TableCell className="operator">
							<EnhancedSelect
								value={row.operator ?? ''}
								options={validOperators}
								onOptionSelect={(e) => context.OnUpdateOperator(index, e as MetadataQueryOperator)}
							/>
						</TableCell>
						<TableCell className="value">
							{row.operator === isSetOperator || row.operator === isNotSetOperator ? null : (
								<ValueField
									type={row.fieldType}
									disable={row.fieldType === null}
									index={index}
									value={row.value}
									options={getFieldValueOptions(row.field)}
									onChange={(e, v) => {
										context.OnUpdateValue(e, v as string)
									}}
									onFieldValueChanged={context.OnUpdateValue}
								/>
							)}
						</TableCell>
					</TableRow>
				)
			})}
		</>
	)
})
