import Banner from '@/Media/Support Services Designation Partner Value Proposition.png'
import './Home.scss'
export const HomePage = () => {
	return (
		<img
			className="home-banner"
			src={Banner}
			title="Support Services Designation Partner Value Proposition"
			alt="Support Services Designation Partner Value Proposition"
		/>
	)
}
