import { createContext, useContext } from 'react'
import { Step1Context } from './Step1Context'
import { makeAutoObservable } from 'mobx'
import { DataQueryContext } from '@components/DataQuery/DataQueryContext'
import { Api } from '@/Api'
import { Step3Context } from './Step3Context'
import { ArticleContext } from './ArticleContext'
import { Step4Context } from './Step4Context'
import { Step5Context } from './Step5Context'
import { TaskStatus } from '@/Api/Types'
export const steps = [
	'Config the migration',
	'Select articles',
	'Retrieve articles',
	'Config articles',
	'Convert articles',
	'Publish articles',
] as const
export type Steps = (typeof steps)[number]
export class NewMigrationContext {
	public constructor({ migrationId }: { migrationId?: string }) {
		const id = Number(migrationId)
		this.step1 = new Step1Context()
		this.step2 = new DataQueryContext(this)
		this.step3 = new Step3Context(this)
		this.step4 = new Step4Context(this)
		this.step5 = new Step5Context(this)
		this.editMode = false
		this.taskStep = steps[0]
		this.articleContext = new ArticleContext()
		this.taskStatus = {
			retrieved: 0,
			included: 0,
			configured: 0,
			converted: 0,
		}
		this.finishLoading = false

		if (!isNaN(id)) {
			this.migrationId = id
			this.editMode = true
			requestIdleCallback(() => {
				this.InitStateForEdit(id).then(() => {
					this.FinishLoading = true
				})
			})
		} else {
			this.FinishLoading = true
			this.migrationId = null
			this.Step2.SetDefaultExpression()
		}
		makeAutoObservable(this)
	}
	public async InitStateForEdit(id: number) {
		await Promise.all([
			Api.MigrationTask.GetTask(id).then((res) => {
				if (res === null) return
				this.Step1.Name = res.migrationName
				this.Step1.Description = res.description
				this.Step1.Disclaimer = res.defaultDisclaimer
				this.Step1.DefaultPermissionType = res.permissionType
				this.Step1.RepoUrl = res.targetRepoUrl
				this.Step1.Docset = {
					folder: res.targetDocSetUrl,
					name: res.targetDocSetName,
					permissionedType: res.permissionType,
				}
				const filter = JSON.parse(res.articleFilter)
				this.Step2.LoadQueryData(filter)
				this.TaskStep = res.taskStep as Steps
			}),
			Api.MigrationTask.GetTaskStatus([id]).then((res) => {
				this.TaskStatus = res[id]
			}),
		])
	}

	private taskStatus: TaskStatus
	public get TaskStatus() {
		return this.taskStatus
	}
	public set TaskStatus(v: TaskStatus) {
		this.taskStatus = v
	}

	private taskStep: Steps
	public get TaskStep() {
		return this.taskStep
	}
	public set TaskStep(v: Steps) {
		this.taskStep = v
	}

	private migrationId: number | null
	public get MigrationId(): number | null {
		return this.migrationId
	}
	public set MigrationId(v: number | null) {
		this.migrationId = v
	}

	private step1: Step1Context
	public get Step1() {
		return this.step1
	}
	private step2: DataQueryContext
	public get Step2() {
		return this.step2
	}
	private step3: Step3Context
	public get Step3() {
		return this.step3
	}
	private step4: Step4Context
	public get Step4() {
		return this.step4
	}
	private step5: Step5Context
	public get Step5() {
		return this.step5
	}

	private articleContext: ArticleContext
	public get ArticleContext() {
		return this.articleContext
	}

	private editMode: boolean
	public get EditMode(): boolean {
		return this.editMode
	}
	public set EditMode(v: boolean) {
		this.editMode = v
	}

	private finishLoading: boolean
	public get FinishLoading(): boolean {
		return this.finishLoading
	}
	public set FinishLoading(v: boolean) {
		this.finishLoading = v
	}
}
export const Context = createContext(new NewMigrationContext({}))
export const useMigrationContext = () => useContext(Context)
