const url = process.env.REACT_APP_BACKEND_API_URL!
export const Env = {
	SonicApiUrl: process.env.REACT_APP_SONIC_API_URL!,
	BackendApiUrl: url.endsWith('/') ? url : url + '/',
	ClientID: process.env.REACT_APP_BACKEND_API_CLIENTID!,
	Scopes: process.env.REACT_APP_BACKEND_API_SCOPES!,
	IssueCenterApiUrl: process.env.REACT_APP_ISSUE_CENTER_API_URL!,
	IsDev: process.env.NODE_ENV === 'development',
	RedirectUrl: process.env.REACT_APP_WEBAPP_REDIRECTURL!,
	Authority: process.env.REACT_APP_AUTHORITY!,
	Insights: process.env.REACT_APP_WEBAPP_INSIGHTS_ID!,
	Sonic: {
		ApiUrl: process.env.REACT_APP_SONIC_API_URL!,
		ClientID: process.env.REACT_APP_SONIC_API_CLIENTID!,
		Scopes: [process.env.REACT_APP_SONIC_API_SCOPES!],
		RedirectUrl: process.env.REACT_APP_SONIC_REDIRECTURL!,
		Authority: process.env.REACT_APP_SONIC_AUTHORITY!,
	},
	Role: {
		All: process.env.REACT_APP_ROLE_ALL!,
	},
	SonicIndex: {
		Index: process.env.REACT_APP_SONIC_INDEX!,
		Name: process.env.REACT_APP_SONIC_INDEXNAME!,
	},
}
